import { useState } from "react";

import Edit from "../../Components/Edit/Edit";
import Work from '../../Components/Work/Work';
import './Schemes.css';

const Schemes = (props) => {
  const { block, status } = props;

  const [expand, setExpand] = useState(null);
  const [edit, setEdit] = useState(true);

  let editingRegion = null;
  let workingRegion = null;
  if (expand !== null && edit === true) {
    editingRegion = <Edit deleteAccess={window.deleteSchemeAccess} type={status} expander={setExpand} entryID = {expand}/>;
    workingRegion = null;
  } else {
    workingRegion = <Work block={block} type={status} expander={setExpand} />;
    editingRegion = null;
  }

  return (
    <div className="container-fluid wide-container">
      { editingRegion ?<div className="row">
        <div className="col-12 work-content">{editingRegion}</div>
      </div>: null}
      
      { workingRegion ? <div className="row">
        <div className="col-12 work-content">{workingRegion}</div>
      </div> : null }
      
    </div>
  );
};

export default Schemes;
