import React,{Component} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import {Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col,  Table , Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { capitalizeFirstLetter } from '../../Services/helper';


class SanctionOrder extends Component{
    state={
        isOpen:false,
        gramPanchayat:'',
        sanctionedFrom:'',
        expM:'',
        expW:'',
        assessedAmount:'',
        contigency:''
    }
    convertToStandardDate = (dateOfCompletion) => {
        var dd = dateOfCompletion.slice(8,10);
        var mm = dateOfCompletion.slice(5,7);
        var yyyy = dateOfCompletion.slice(0,4);
        dateOfCompletion = dd + '/' + mm + '/' + yyyy;
        return dateOfCompletion;
      }
    changeHandler=(event,workID)=>{
        this.setState({[workID]:event.target.checked});
    }

    modalEntryHandler = (event,identifier) => {
        this.setState({[identifier]:event.target.value});
      }

    produceCertificate = () =>{
        let content = this.props.content;
        let selectedContent  = content.filter((work)=>{
            if(this.state[work.workID]!=undefined && this.state[work.workID]===true){
                return true;
            }
            else{
                return false;
            } 
        });

      

        let myContentArr = [];
        selectedContent.forEach(element => {
            const arr = this.generateCertificateObject(element);
            myContentArr.push(...arr);
        });

        if(selectedContent.length>0){
            const {vfs} = vfsFonts.pdfMake;
            pdfMake.vfs = vfs;
            var dd = {
                content: myContentArr,
                styles: {
                    header: {
                      fontSize: 18,
                      bold: true
                    },
                    bigger: {
                      fontSize: 15,
                      italics: true
                    }
                  }
            }
            var pdf = pdfMake.createPdf(dd);
            pdf.download();
        }  
    }
  
   toggle=()=>{
      const prev = this.state.isOpen;
      this.setState({isOpen:!prev});
  }
    
    generateCertificateObject = (data) => {
        var today = new Date();
        
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;
        return [
            {
                text: ' Utilization/Completion  Certificate \n\n',
                style: 'header',
                alignment: 'center'
                },
              ' ',
              {
                text: `Work / Scheme ID: ${data.workID}                                        Name of Panchayat :${data.gramPanchayat} `,
                style: 'subheader'
              },
              ' ',
              {
                text: `1. Name of Work/Scheme : ${data.nameOfWork}`,
                style: 'subheader'
              },
              ' ',
              
              {
                text: `2. Head : ${data.head}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: `3. Financial Year : ${data.financialYear}` ,
                style: 'subheader'
              
                  },
              ' ',
                  {
                text: `4. Sanction No. : ${data.sancNo}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: `5. Sanction Date : ${this.convertToStandardDate(data.sancDate)}`,
                style: 'subheader'
              },
              ' ',
              {
                text: `6. Sanction Amount : ${data.sancAmount}`,
                style: 'subheader'
              },
              ' ',	
                
                  {
                text: `7. Date of Completion : ${this.convertToStandardDate(data.dateOfRelease[data.dateOfRelease.length-1])}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: `8. Expenditure : ${data.sancAmount}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: `9. Date of issue of utilization certificate : ${today}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: `10. MB No. : ${data.mbNo[data.mbNo.length-1]}                             Page :${data.page[data.page.length-1]}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: `11. Mendays generated : ${data.menDays[data.menDays.length-1]} `,
                style: 'subheader'
              },
              ' ',
                  {
                text: `12. Work Ragister Page No.  :  ${data.workRegisterPage[data.workRegisterPage.length-1]}`,
                style: 'subheader'
              },
              ' ',
                  {
                text: 'Certified that the work has been completed / sanctioned amount utilized as per estimate in proposed work',
                style: 'subheader'
              },
              ' ',' ',' ',' ',' ',' ',' ',
              
                  {
                text: 'Junior Engineer                                                                                         Block Development Officer ',
                        style: 'subheader'
                  },
          
              
                 {
                text: `Dev. Block ${capitalizeFirstLetter(window.block)}                                                                                      Development Block ${capitalizeFirstLetter(window.block)}  `,
                        style: 'subheader',
                        pageBreak: 'after'
                  }
        ]
    }

    render(){
        return(
            <div>
            <Button color="info" onClick={this.toggle} block>GENERATE UTILIZATION CERTIFICATES</Button>
      <Modal isOpen={this.state.isOpen} toggle={this.toggle} style={{"max-width": "98%", "overflow": "scroll"}} >
      <ModalHeader toggle={this.toggle}><div><h2><Badge color="warning">Download UC here</Badge></h2></div></ModalHeader>
     
      <ModalBody style={{"overflow": "scroll"}}>

        <Table hover responsive="sm">
                <thead>
                  <tr>
                    <th>CHECK</th>
                    <th>#</th>
                    <th>Work ID</th>
                    <th>Head</th>
                    <th>Name of work</th>
                    <th>Gram Panchayat</th>
                    <th>Sanction No</th>
                    <th>Sanction Date</th>
                    <th>Sanction Amount</th>
                    <th>Financial Year</th>
          
                  </tr>
                </thead>
                
                     <tbody>
                     {this.props.content.map((work,index) => (
                         <tr key={work.id }>
                            <td><Input style={{color: "red"}} type="checkbox" onChange={(event)=>this.changeHandler(event,work.workID)} checked={this.state[work.workID]!=undefined && this.state[work.workID]}/></td>
                             <th>{index+1}</th>
                             <td>{work.workID}</td>
                             <td>{work.head}</td>
                             <td>{work.nameOfWork}</td>
                             <td>{work.gramPanchayat}</td>
                             <td>{work.sancNo}</td>
                             <td>{work.sancDate}</td>
                             <td>{work.sancAmount}</td>
                             <td>{work.financialYear}</td>
                         </tr>
                     ))}
                     </tbody>
                
                </Table>


     </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.produceCertificate}>Produce Certificate</Button>{' '}
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
        </div>
        );
    }
}

export default SanctionOrder;