import React,{Component} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts'
import {Button} from 'reactstrap';

class Certificate extends Component{
 
  generateCertificate = () => {
    // const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfsFonts;
        var dd = {
            content: [
        {
            text : 'hi ', //'अंतरिक्ष यान से दूर नीचे पृथ्वी शानदार ढंग से जगमगा रही थी ।',
        }
        ]
        };
  
    var pdf = pdfMake.createPdf(dd);
    pdf.download();
  }
  
  render(){
    return(
      <Button color="primary" onClick={this.generateCertificate}>HINDI</Button>
    );
  }
}



export default Certificate;


