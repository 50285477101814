import firebase from "firebase/compat/app";
import { Table, Badge } from "reactstrap";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";

import Card from "react-bootstrap/Card";

import "./UserConsole.css";
import { deregisterUser, getRegisteredUsers, getUnregisteredUsers, getUserDetails, registerUser, updateUser } from "../../Services/services";

const UserConsole = (props) => {
  const { token } = props;
  const [unregisteredUsers, setUnregisteredUsers] = useState({});
  const [registeredUsers, setRegisteredUsers] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [registeringUser, setRegisteringUser] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [forceUpdate, setForeUpdate] = useState(false);
  
  useEffect(() => {
    (async () => {
        try {
            const userDetails = await getUserDetails(selectedUser, token);
            setUserDetails(userDetails);
        } catch (err) {
        }
    })();
    return () => {

    }
  }, [selectedUser, forceUpdate]);



  useEffect(() => {
    (async () => {
      try {
        if (token) {
          const unregisteredUsers = await getUnregisteredUsers(token);
          const registeredUsers = await getRegisteredUsers(token);
          const filteredRegisteredUsers = {};
          Object.keys(registeredUsers).forEach((user)=>{
            if(registeredUsers[user]?.block === window.block)
                filteredRegisteredUsers[user] = registeredUsers[user];
          });
          setRegisteredUsers(filteredRegisteredUsers);
          setUnregisteredUsers(unregisteredUsers);
        }
      } catch (err) {
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [token, registeringUser, forceUpdate]);

  const handleSelectedUser = (userId) => {
    return () => {
      setSelectedUser(userId);
    };
  };

  const handleRegistration = (userId) => {
    return async() => {
        setRegisteringUser(userId);
        if (userId && token && unregisteredUsers[userId].email && window.block){
        await registerUser(userId, token, unregisteredUsers[userId].email, window.block);
    }
        setRegisteringUser(null);
        setForeUpdate((val) => !val );
    }
  }

  const handleDeregisteration = () => {
    deregisterUser(selectedUser, token, userDetails?.email).then(() => {setForeUpdate((val) => !val ); setSelectedUser(null); });
  }

  const handlePermissionChanges = (permission, current) => {
    const want = !current;
    const currentUserDetail = {...userDetails};
    currentUserDetail.schemes = currentUserDetail.schemes || {};
    currentUserDetail.panchayats = currentUserDetail.panchayats || {};
    currentUserDetail.users = currentUserDetail.users || {};
    
    currentUserDetail['schemes'].read = true;
    currentUserDetail['panchayats'].read = true;
    
    if (permission === 'ADD_SCHEME'){
        currentUserDetail['schemes'].add = want;
    } else if (permission === 'UPDATE_SCHEME'){
        currentUserDetail['schemes'].update = want;
    } else if (permission === 'DELETE_SCHEME'){
        currentUserDetail['schemes'].delete = want;
    } else if (permission === 'ADD_NEW_PANCHAYATS') {
        currentUserDetail['panchayats'].add = want 
    } else if (permission === 'DELETE_PANCHAYATS') {
        currentUserDetail['panchayats'].delete = want;
        currentUserDetail['panchayats'].update = want;
    } else if (permission === 'UPDATE_USER') {
        currentUserDetail['users'].update = want;
        currentUserDetail['users'].add = want;
        currentUserDetail['users'].read = want;
        currentUserDetail['users'].delete = want;
    }
    updateUser(token, selectedUser, currentUserDetail).then(()=>{ setForeUpdate((val) => !val )});
  }

  return (
    <div className="startpage">
       { (!selectedUser) ? 
      <Card className="startpage" style={{ color: "black" }}>
        <Card.Header style={{ width: "100%" }}>User Console</Card.Header>
        <Card.Body>
          <Card.Title>Use this console to edit user details</Card.Title>
          <Table striped responsive size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Registered Users</th>
              </tr>
            </thead>

            <tbody>
              {registeredUsers && Object.keys(registeredUsers).map((userId, index) => (
                <tr key={userId}>
                  <th>User {index + 1}</th>
                  <td>{registeredUsers?.[userId]?.email}</td>
                  <td><Button color="primary" onClick={handleSelectedUser(userId)} >Edit </Button></td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Table striped responsive size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Unregistered Users</th>
              </tr>
            </thead>

            <tbody>
              {unregisteredUsers && Object.keys(unregisteredUsers).map((userId, index) => (
                <tr key={userId}>
                  <th>User {index + 1}</th>
                  <td>{unregisteredUsers?.[userId]?.email}</td>
                  <td><Button color="warning" disabled={registeringUser===userId} onClick={handleRegistration(userId)}>Register</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>

        </Card.Body>
      </Card>
:
      <Card className="startpage" style={{ color: "black" }}>
        <Card.Header style={{ width: "100%" }}>User Details</Card.Header>
        <Card.Body>
          <Card.Text>
            Email: <strong>{userDetails?.email}</strong>
          </Card.Text>

          <Card.Title>Grant following permissions</Card.Title>

          <Card.Text>
            Block: <strong>{userDetails?.block || 'Will be registered in your block'}</strong>
          </Card.Text>

          <Table responsive size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Permission</th>
                <th>Not/Allowed</th>
              </tr>
            </thead>

            <tbody>

              <tr>
                <td>1</td>
                <td>Add Schemes</td>
                <td><Button onClick={() => handlePermissionChanges('ADD_SCHEME', userDetails?.schemes?.add)} color={userDetails?.schemes?.add ? "success": "secondary" }>{ userDetails?.schemes?.add ? "Allowed" : "Not Allowed"}</Button></td>
              </tr>

              <tr>
                <td>2</td>
                <td>Update Schemes</td>
                <td><Button onClick={() => handlePermissionChanges('UPDATE_SCHEME', userDetails?.schemes?.update)} color={userDetails?.schemes?.update ? "success": "secondary" }>{ userDetails?.schemes?.update ? "Allowed" : "Not Allowed"}</Button></td>
              </tr>

              <tr>
                <td>3</td>
                <td>Delete Schemes</td>
                <td><Button onClick={() => handlePermissionChanges('DELETE_SCHEME', userDetails?.schemes?.delete)} color={userDetails?.schemes?.delete ? "success": "secondary" }>{ userDetails?.schemes?.delete ? "Allowed" : "Not Allowed"}</Button></td>
              </tr>

              <tr>
                <td>4</td>
                <td>Add Panchayats</td>
                <td><Button onClick={() => handlePermissionChanges('ADD_NEW_PANCHAYATS', userDetails?.panchayats?.add)} color={userDetails?.panchayats?.add ? "success": "secondary" }>{ userDetails?.panchayats?.add ? "Allowed" : "Not Allowed"}</Button></td>
              </tr>

              <tr>
                <td>5</td>
                <td>Remove Panchayats</td>
                <td><Button onClick={() => handlePermissionChanges('DELETE_PANCHAYATS', userDetails?.panchayats?.delete)} color={userDetails?.panchayats?.delete && userDetails?.panchayats?.update ? "success": "secondary" }>{ userDetails?.panchayats?.delete && userDetails?.panchayats?.update ? "Allowed" : "Not Allowed"}</Button></td>
              </tr>

              <tr>
                <td>6</td>
                <td>Make changes to User Permissions</td>
                <td><Button onClick={() => handlePermissionChanges('UPDATE_USER', userDetails?.users?.add && userDetails?.users?.update && userDetails?.users?.read && userDetails?.users?.delete)} color={userDetails?.users?.add && userDetails?.users?.update && userDetails?.users?.read && userDetails?.users?.delete ? "success": "secondary" }>{ userDetails?.users?.add && userDetails?.users?.update && userDetails?.users?.read && userDetails?.users?.delete ? "Allowed" : "Not Allowed"}</Button></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td>
                  {" "}
                  <Button color="danger" onClick={handleDeregisteration}>
                    Deregister the user
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>

          <Button block color="dark" onClick={() => setSelectedUser(null)}>
            Exit
          </Button>
        </Card.Body>
      </Card>
      }
    </div>
  );
};

export default UserConsole;
