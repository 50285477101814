import React, { Component } from 'react';
import { Card, CardTitle, CardText  } from 'reactstrap';

import { Col, Button, Form, FormGroup, Label, Input, FormText ,Badge} from 'reactstrap';
import './NewScheme.css'
import Spinner from '../Spinner/Spinner';
import axios from '../../Services/axios';
import { getPanchayats } from '../../Services/services';

class Newscheme extends Component {
  state = {
    panchayats: {},
    form: {
      head: "",
      workID: "",
      nameOfWork: "",
      gramPanchayat: "",
      sancNo: "",
      sancDate: "",
      sancAmount: "",
      financialYear:"",
      
      mbNo:[''],
      page:[''],
      workRegisterPage:[''],
      menDays:[''],
      
      dateOfCompletion: "",
      dateOfRelease: [''],
      releaseAmount: [''],
      balance: [''],
      status: [''],
      description: "",
      edit: ['']
    },
    validation:{
      head:              {filled:false ,changed:false ,group:'all'},
      workID:            {filled:false ,changed:false ,group:'all'},
      nameOfWork:        {filled:false ,changed:false ,group:'all'},
      gramPanchayat:     {filled:false ,changed:false ,group:'all'},
      sancNo:            {filled:false ,changed:false ,group:'all'},
      sancDate:          {filled:false ,changed:false ,group:'all'},
      sancAmount:        {filled:false ,changed:false ,group:'all'},
      financialYear:     {filled:false ,changed:false ,group:'all'},
 
      // mbNo:              {filled:false ,changed:false ,group:'all'},
      // page:              {filled:false ,changed:false ,group:'all'},
      // workRegisterPage:  {filled:false ,changed:false ,group:'all'},
      // menDays:           {filled:false ,changed:false ,group:'all'},
      
 
      dateOfRelease:     {filled:false ,changed:false ,group:'allOrNone'},
      releaseAmount:     {filled:false ,changed:false ,group:'allOrNone'},
      balance:           {filled:false ,changed:false ,group:'allOrNone'},
      status:            {filled:false ,changed:false ,group:'allOrNone'},
       
      dateOfCompletion:  {filled:false ,changed:false ,group:'optional'},
      description:       {filled:false ,changed:false ,group:'optional'},
      edit:              {filled:false ,changed:false ,group:'optional'}
    },
    loading: false,
    formIsValid:false,
    trySubmitOnce:false,
    result: 'not-sent', //failed success
    showerr: ''
  }

  componentDidMount() {
    this.getAllPanchayats();
  }

  getAllPanchayats = () => {
    getPanchayats(window.token, window.block).then((val) => {
      this.setState({ panchayats: val });
      Object.keys(val).map((panchayat, index) => {
      })
    });
  };

  extractStartYear=(str) => {
    return str.slice(0,4);
  }

  makeFinancialYear = (stYr) => {
    let startYear = parseInt(stYr);
    let endYear = startYear + 1;
    let financialYear = "";
    financialYear = startYear+'-'+parseInt(endYear/10)%10 + endYear%10;
    return financialYear; 
  }

  inputChangeHandler = (event, inputIdentifier) => {
    const updatedForm = { ...this.state.form };

    if(inputIdentifier === 'balance' || inputIdentifier==='releaseAmount'||inputIdentifier==='status' || inputIdentifier==='dateOfRelease'){
      updatedForm[inputIdentifier][0] = event.target.value; 
    }
    else{
      updatedForm[inputIdentifier] = event.target.value;
    }

    updatedForm.edit[0] = window.nameOfEmp || window.email || 'NO_NAME';
    const validationForm = {...this.state.validation};
    const colEntry = {...this.state.validation[inputIdentifier]};
    colEntry.changed = true;
    if(inputIdentifier === 'financialYear'){
      colEntry.filled = parseInt(event.target.value) > 999 && parseInt(event.target.value) < 9998;
    }
    else{
      colEntry.filled = event.target.value.trim()?true:false ;
    }
    validationForm[inputIdentifier] = colEntry;
    this.setState({ form: updatedForm,validation:validationForm});
  }


  validity = (inputIdentifier) => {

    if(this.state.validation[inputIdentifier].group ==='all'){
        return (!this.state.validation[inputIdentifier].filled && (this.state.validation[inputIdentifier].changed || this.state.trySubmitOnce));
    }
    else if(this.state.validation[inputIdentifier].group ==='allOrNone'){
        let checkIfAnyFilled = false;

        for(let key in this.state.validation){
            if(this.state.validation[key].group==='allOrNone'){
                checkIfAnyFilled = checkIfAnyFilled || this.state.validation[key].filled;
            }
        }
        return ((checkIfAnyFilled === !this.state.validation[inputIdentifier].filled) && (this.state.validation[inputIdentifier].changed || this.state.trySubmitOnce));
    }
    else 
        return false;
    }



  checkFormValidity(){
    let completeValidity = true;
    let groupAll = true;
    let groupAllOrNone = true;
    let groupOptional = true;

    let checkIfAnyFilled = false;

    for(let key in this.state.validation){
        if(this.state.validation[key].group==='allOrNone'){
            checkIfAnyFilled = checkIfAnyFilled || this.state.validation[key].filled;
        }
    }
 
    
    for(let key in this.state.validation){
        if(this.state.validation[key].group==='all'){
            groupAll = groupAll &&  this.state.validation[key].filled;
        }
        else if(this.state.validation[key].group==='allOrNone'){
            groupAllOrNone = groupAllOrNone && (checkIfAnyFilled === this.state.validation[key].filled);
        }
        else if(this.state.validation[key].group==='optional'){
            // groupOptional = groupOptional ;
        }
    }
    
    completeValidity = groupAll && groupAllOrNone && groupOptional;
    return completeValidity;
  }

  addNewHandler = () => {
    const formValidity = this.checkFormValidity();
    if(formValidity){
      this.setState({ loading: true ,trySubmitOnce:true});
      const newScheme = {};
      for (let key in this.state.form) {
        newScheme[key] = this.state.form[key];
      }
      newScheme.financialYear = this.makeFinancialYear(newScheme.financialYear);
      let wi = newScheme.workID;
      axios.get(`/blocks/${window.block}/schemes.json?auth=`+window.token).then(res => {
        for(let key in res.data){
          let W = res.data[key]["workID"];

          
          if(wi.trim().toLowerCase() == W?.trim().toLowerCase())
            throw 'Scheme already exists';   
        }

        axios.post(`/blocks/${window.block}/schemes.json?auth=${window.token}`, newScheme).then(response => {
          this.setState({ result: 'success', loading: false,showerr:'' });
        }).catch(error => {
          this.setState({ result: 'failed', loading: false });
        });

      }).catch(err=>{
        this.setState({ result: 'failed', loading: false ,showerr:'Scheme is already registerd or there is some kind of network issue' });
      });
    }
    else{
      this.setState({trySubmitOnce:true});
    }

  }

  render() {

    return (
      <div className="container inputArea">
        <div className="row">
          <div className="col-12">
            <Card body className="card">
              <CardTitle><h2>Add New Scheme</h2></CardTitle>
              {/* <CardText>Here you can add new schemes</CardText> */}
              {/* <FormText color="muted">
                {this.state.showerr}
              </FormText> */}
              <CardTitle><h3>{this.state.showerr.length?<Badge color="warning">{this.state.showerr}</Badge>:null}</h3></CardTitle>
              <Form>
                <hr />

                <FormGroup row>
                  <Label for="Head" sm={2}>Head</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('head')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'head')} type="text" name="head" placeholder="Eg. Head" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="workid" sm={2}>Work ID</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('workID')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'workID')} type="text" name="workid" placeholder="Eg. 1234HEF" />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="nameofwork" sm={2}>Name Of Work</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('nameOfWork')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'nameOfWork')} type="text" name="nameofwork" placeholder="Eg. 1234HEF" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="grampanchayat" sm={2}>Gram Panchayat</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('gramPanchayat')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'gramPanchayat')} type="select" name="select">
                      <option>Select One</option>
                      {
                        (Object.keys(this.state.panchayats) || [] ).map((panchayat, index) => 
                          <option key={index}>{this.state.panchayats[panchayat].name}</option>
                        )
                      }
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="sancno" sm={2}>Sanc. No</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('sancNo')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'sancNo')} type="text" name="sancno" placeholder="Eg. 1234" />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="sancdate" sm={2}>Sanc. Date</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('sancDate')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'sancDate')} type="date" name="workid" placeholder="Eg. 1234HEF" />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="sancamount" sm={2}>Sanc. Amount</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('sancAmount')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'sancAmount')} type="number" name="sancamount" placeholder="Eg. 20000" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="financialYear" sm={2}>Financial Year</Label>
                  <Col sm={4}>
                    <Input invalid={this.validity('financialYear')} value={this.state.form.financialYear} onChange={(event) => this.inputChangeHandler(event, 'financialYear')} type="number" name="financialYear" placeholder="Eg. 2010" />
                  </Col>
                  <Label sm={1}> to </Label>
                  <Col sm={4}>
                    <Input  value={parseInt(this.state.form.financialYear)+1} type="number" name="financialYearEnd" placeholder="Eg. 2011" />
                  </Col>
                  
                </FormGroup>



                {/* <FormGroup row>
                  <Label for="mbNo" sm={2}>MB No</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('mbNo')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'mbNo')} type="number" name="mbNo" placeholder="Eg. 1574" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="page" sm={2}>Page</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('page')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'page')} type="number" name="page" placeholder="Eg. 26" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="workRegisterPage" sm={2}>Work Register Page</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('workRegisterPage')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'workRegisterPage')} type="number" name="workRegisterPage" placeholder="Eg. 1574" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="menDays" sm={2}>Men Days</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('menDays')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'menDays')} type="number" name="menDays" placeholder="Eg. 26" />
                  </Col>
                </FormGroup>
 */}

                <FormText color="muted">
                  Remaining form can be filled afterwards
                          </FormText>
                <hr />
                <FormGroup row>
                  <Label for="dateofrelease" sm={2}>Date Of Release</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('dateOfRelease')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'dateOfRelease')} type="date" name="dateofrelease" placeholder="Eg. 1234HEF" />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="release amount" sm={2}>Release Amount</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('releaseAmount')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'releaseAmount')} type="number" name="releaseamount" placeholder="Eg. 10000" />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="balance" sm={2}>Balance</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('balance')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'balance')} type="number" name="balance" placeholder="Eg. 10000" />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="status" sm={2}>Status</Label>
                  <Col sm={10}>
                    {/* <Input invalid={this.validity('status')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'status')} type="status" name="status" placeholder="Eg. Status" /> */}
                    <Input invalid={this.validity('status')} value={this.state.value} onChange={(event) => this.inputChangeHandler(event, 'status')} type="select" name="select">
                      <option>Select One</option>
                      <option>In Progress</option>
                      <option>Not Started</option>
                      <option>Completed</option>
                    </Input>
                  </Col>
                </FormGroup>

                <hr />

                <FormGroup row>
                  <Label for="description" sm={2}>Description</Label>
                  <Col sm={10}>
                    <Input invalid={this.validity('description')}  onChange={(event) => this.inputChangeHandler(event, 'description')} type="textarea" name="description" placeholder="Can be left blank" />
                  </Col>
                </FormGroup>


                <FormGroup row>
                  <Label for="editby" sm={2}>Created By</Label>
                  <Col sm={7}>
                    <Input invalid={this.validity('edit')} type="text" name="status" value={this.state.form.edit} />
                  </Col>
                  <Col sm={2}>
                    <Button disabled={this.state.result === 'success'} onClick={this.addNewHandler} color={this.state.result === 'not-sent' ? 'primary' : (this.state.result === 'success' ? 'success' : 'danger')} >Add this Work</Button>
                  </Col>
                  <Col sm={1}>
                    {this.state.loading ? <Spinner /> : <div></div>}
                  </Col>

                </FormGroup>
              </Form>

            </Card>

          </div>
        </div>
      </div>
    );
  }
}

export default Newscheme;