import React, { useState ,Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import { Badge ,Input ,Label,Row,Col,Button,Tooltip} from 'reactstrap';
import { thistle } from 'color-name';

class FilterNav extends Component{
    render(){
        return (
            <div>
              <Navbar color="light" light expand="md">
                <NavbarBrand  >Filter Bar</NavbarBrand>
                {/* <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar> */}
                  <Nav className="mr-auto" navbar>
        
                    <NavItem>
                      <NavLink active={this.props.activeField==='workID'?true:false} onClick={()=>this.props.activeFieldModeHandler('workID','singleValue','text')}>Work ID</NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink active={this.props.activeField==='head'?true:false} onClick={()=>this.props.activeFieldModeHandler('head','singleValue','text')}>Head</NavLink>
                    </NavItem>

                    {/* <NavItem>
                      <NavLink active={this.props.activeField==='nameOfWork'?true:false} onClick={()=>this.props.activeFieldModeHandler('nameOfWork','singleValue','text')} >Name Of Work</NavLink>
                    </NavItem> */}
             
                    <NavItem>
                      <NavLink active={this.props.activeField==='gramPanchayat'?true:false} onClick={()=>this.props.activeFieldModeHandler('gramPanchayat','singleValue','text')} >Gram Panchayat</NavLink>
                    </NavItem>
                
                    <NavItem>
                      <NavLink active={this.props.activeField==='sancNo'?true:false} onClick={()=>this.props.activeFieldModeHandler('sancNo','singleValue','number')} >Sanction No</NavLink>
                    </NavItem>
                
                
                    <UncontrolledDropdown nav inNavbar active={this.props.activeField==='financialYear'?true:false}>
                      <DropdownToggle nav caret>
                        Financial Year
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('financialYear','singleValue','text',0)} >
                          Greater Than
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('financialYear','singleValue','text',1)} >
                          Less Than
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('financialYear','singleValue','text',2)} >
                          Equal To
                        </DropdownItem>
        
                        <DropdownItem divider />
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('financialYear','range','text')} >
                          Greater Than and Less Than
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>


                    <UncontrolledDropdown nav inNavbar active={this.props.activeField==='sancDate'?true:false} >
                      <DropdownToggle nav caret >
                       SanctionDate
                        {/* <NavItem> */}
                            {/* <NavLink>Sanction Date</NavLink> */}
                        {/* </NavItem> */}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('sancDate','singleValue','date',0)} >
                          After
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('sancDate','singleValue','date',1)} >
                          Before
                        </DropdownItem>
                        <DropdownItem  onClick={()=>this.props.activeFieldModeHandler('sancDate','singleValue','date',2)}  >
                          On
                        </DropdownItem>
                        <DropdownItem divider />
        
                        <DropdownItem  onClick={()=>this.props.activeFieldModeHandler('sancDate','range','date')}  >
                          After and Before
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
        
        
                    <UncontrolledDropdown nav inNavbar active={this.props.activeField==='sancAmount'?true:false}>
                      <DropdownToggle nav caret>
                        SanctionAmount
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('sancAmount','singleValue','number',0)} >
                          Greater Than
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('sancAmount','singleValue','number',1)} >
                          Less Than
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('sancAmount','singleValue','number',2)} >
                          Equal To
                        </DropdownItem>
        
                        <DropdownItem divider />
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('sancAmount','range','number')} >
                          Greater Than and Less Than
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
        
        
                    <UncontrolledDropdown nav inNavbar active={this.props.activeField==='dateOfRelease'?true:false}>
                      <DropdownToggle nav caret>
                        Date Of First Release
                      </DropdownToggle>
                      <DropdownMenu right>
                      <DropdownItem onClick={()=>this.props.activeFieldModeHandler('dateOfRelease','singleValue','date',0)} >
                          After
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('dateOfRelease','singleValue','date',1)} >
                          Before
                        </DropdownItem>
                        <DropdownItem  onClick={()=>this.props.activeFieldModeHandler('dateOfRelease','singleValue','date',2)}  >
                          On
                        </DropdownItem>
                        <DropdownItem divider />
        
                        <DropdownItem  onClick={()=>this.props.activeFieldModeHandler('dateOfRelease','range','date')}  >
                          After and Before
                        </DropdownItem>
                </DropdownMenu>
                    </UncontrolledDropdown>
        
                    <UncontrolledDropdown nav inNavbar active={this.props.activeField==='dateOfCompletion'?true:false}>
                      <DropdownToggle nav caret>
                        Date Of Completion
                      </DropdownToggle>
                      <DropdownMenu right>
                      <DropdownItem onClick={()=>this.props.activeFieldModeHandler('dateOfCompletion','singleValue','date',0)} >
                          After
                        </DropdownItem>
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('dateOfCompletion','singleValue','date',1)} >
                          Before
                        </DropdownItem>
                        <DropdownItem  onClick={()=>this.props.activeFieldModeHandler('dateOfCompletion','singleValue','date',2)}  >
                          On
                        </DropdownItem>
                        <DropdownItem divider />
        
                        <DropdownItem onClick={()=>this.props.activeFieldModeHandler('dateOfCompletion','range','date')}  >
                          After and Before
                        </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
        
                   <NavItem>
                       {this.props.mode==='range'?  
                    <Row form>
                        <Col sm={5}>
                        <Input onChange={(event)=>this.props.dualInputHandler(event,0)} value={this.props.filterData[this.props.activeField][0]} type={this.props.type} placeholder="after" />
                        
                        </Col>
                        <Col sm={2}>
                        <NavItem>
                            <NavLink>b/w</NavLink>
                        </NavItem>
                        </Col>
                      
                        <Col sm={5}>
                        <Input onChange={(event)=>this.props.dualInputHandler(event,1)} value={this.props.filterData[this.props.activeField][1]} type={this.props.type} placeholder="before" />
                        
                        </Col>
                    </Row>
                  :<Input onChange={this.props.inputHandler} type={this.props.type} value={this.props.index===-1?this.props.filterData[this.props.activeField]:this.props.filterData[this.props.activeField][this.props.index]} placeholder={this.props.activeField}/>}
                   </NavItem>
                  <NavItem>
                    <Button id="clearAllFilters" onClick={this.props.clearAllFilters} color="danger">X</Button>
                  </NavItem>
                  </Nav>
                 
                {/* </Collapse> */}
              </Navbar>
            </div>
          );
            }
}


export default FilterNav;