import React, { Component } from "react";

import { Form, FormGroup, Label, Input, Col } from "reactstrap";

class Print extends Component {
  state = {
    fields: [
      "Head",
      "Work ID",
      "Name Of Work",
      "Gram Panchayat",
      "Sanction No.",
      "Sanction. Date",
      "Sanction. Amount",
      "Financial Year",
      "MB No",
      "Page",
      "Work Register Page",
      "Men Days",
      "Date Of Release",
      "Release Amount",
      "Balance",
      "Status",
    ],
    Labels: {
      head: { field: "Head", value: false },
      // workID:          {field:'Work ID',value:false},
      nameOfWork: { field: "Name Of Work", value: false },
      gramPanchayat: { field: "Gram Panchayat", value: false },
      sancNo: { field: "Sanction. No", value: false },
      sancDate: { field: "Sanction. Date", value: false },
      financialYear: { field: "Financial Year", value: false },

      mbNo: { field: "MB No", value: false },
      page: { field: "Page", value: false },
      workRegisterPage: { field: "Work Register Page", value: false },
      menDays: { field: "Men Days", value: false },

      sancAmount: { field: "Sanction. Amount", value: false },
      dateOfRelease: { field: "Date Of Release", value: false },
      releaseAmount: { field: "Release Amount", value: false },
      balance: { field: "Balance", value: false },
      status: { field: "Status", value: false },
    },
  };
  checkChangeHandler = (event, identifier) => {
    let newState = { ...this.state.Labels };
    let newEntry = { ...this.state.Labels[identifier] };
    newEntry.value = event.target.checked;
    newState[identifier] = newEntry;

    const labels = [];
    const labelsName = [];
    for (let key in newState) {
      if (newState[key].value === true) {
        labels.push(key);
        labelsName.push(this.state.Labels[key].field);
      }
    }
    this.props.labelChangeHandler(labels, labelsName);
    this.setState({ Labels: newState });
  };
  render() {
    let selectionFields = [];
    for (let key in this.state.Labels) {
      selectionFields.push(
        <div key={this.state.Labels[key].field}>
          <Label check>
            <Input
              onChange={(event) => this.checkChangeHandler(event, key)}
              key={this.state.Labels[key].field}
              id={this.state.Labels[key].field}
              type="checkbox"
            />{" "}
            {this.state.Labels[key].field}
          </Label>
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h3>What to include in Print ?</h3>
            <FormGroup>{selectionFields}</FormGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default Print;
