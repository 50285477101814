import React,{Component} from 'react';
import { Card, CardTitle, CardText ,Badge} from 'reactstrap';

import Table from '../Table/Table';

import './Work.css';
class Work extends Component{
    render(){
        return(
                       <Card body className="work">
                            <Table type={this.props.type} expander={this.props.expander}/>  
                      </Card>
        );
    }
}

export default Work;