import { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import "./Navbar.css";

const NavigationBar = (props) => {
  const { brandText, localizationText, currentPage, setPage, PAGES } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="navfixed">
      <Navbar color="dark" dark expand="md">
        <NavbarBrand>{brandText}</NavbarBrand>
        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {Object.keys(PAGES).map((element, index) => (
              <NavItem key={index}>
                <NavLink
                  onClick={() => setPage(PAGES[element])}
                  active={currentPage === PAGES[element]}
                >
                  {PAGES[element]}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
        <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink>{localizationText}</NavLink>
                </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
