import { useEffect } from "react";
import { useState } from "react";
import NavigationBar from "../../Components/Navbar/Navbar";
import { createUser, getUserDetails } from "../../Services/services";
import Schemes from "../Schemes/Schemes";
import Start from "../Start/Start";
import NewScheme from "../../Components/NewScheme/NewScheme";
import NewPanchayat from "../../Components/NewPanchayat/NewPanchayat";

import "./Layout.css";
import UserConsole from "../UserConsole/UserConsole";

const PAGES = {
  START: "Start",
  ADD_NEW_PANCHAYAT: "Add New Panchayat",
  ADD_NEW_SCHEME: "Add New Scheme",
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  ALL: "All",
  USER_CONSOLE: "User Console",
  // ADMIN_CONSOLE: "Admin Console",
};

const brandText = "KEEP CHECK";

const Layout = () => {
  const [page, setPage] = useState(PAGES.START);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const [userDb, setUserDb] = useState(null);

  useEffect(() => {
    (async () => {
      window.token = token;
      try {
        if (user?.uid && user?.email) {
          const userDetails = await getUserDetails(user?.uid, token);
          if (!userDetails) {
            await createUser(user?.uid, token, user?.email);
          } else {
            setUserDb(userDetails);
          }
        }
      } catch (err) {
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, [token, user]);

  useEffect(() => {
    window.block = userDb?.block;
    window.nameOfEmp = user?.displayName;
    window.deleteSchemeAccess = userDb?.schemes?.delete;
    
  }, [userDb]);

  let renderingElement = null;
  switch (page) {
    case PAGES.START:
      renderingElement = (
        <Start
          userDb={userDb}
          setUser={setUser}
          user={user}
          setToken={setToken}
          token={token}
        />
      );
      break;
    case PAGES.IN_PROGRESS:
      renderingElement = (
        <Schemes block={userDb?.block} status={PAGES.IN_PROGRESS} />
      );
      break;
    case PAGES.ALL:
      renderingElement = <Schemes block={userDb?.block} status={PAGES.ALL} />;
      break;
    case PAGES.COMPLETED:
      renderingElement = (
        <Schemes block={userDb?.block} status={PAGES.COMPLETED} />
      );
      break;
    case PAGES.NOT_STARTED:
      renderingElement = (
        <Schemes block={userDb?.block} status={PAGES.NOT_STARTED} />
      );
      break;
    case PAGES.ADD_NEW_SCHEME:
      renderingElement = <NewScheme />;
      break;
    case PAGES.ADD_NEW_PANCHAYAT:
      renderingElement = <NewPanchayat />;
      break;
    case PAGES.USER_CONSOLE:
      renderingElement = <UserConsole token={window.token}/>;
      break;
  }
  let input = <div className="background"></div>;
  return (
    <div>
      <div>{input}</div>
      <div className="container mainarea"></div>

      <NavigationBar
        localizationText={userDb?.block || "UNREGISTERED"}
        brandText={brandText}
        currentPage={page}
        setPage={setPage}
        PAGES={token ? PAGES : {}}
      />

      {renderingElement}
    </div>
  );
};

export default Layout;
