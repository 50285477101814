import "bootstrap/dist/css/bootstrap.css";

import Layout from "./Containers/Layout/Layout";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Layout />
      </header>
    </div>
  );
}

export default App;
