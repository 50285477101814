import firebase from "firebase/compat/app";
import { auth } from "firebaseui";
import { useEffect } from "react";
import { Button } from "reactstrap";

import Card from "react-bootstrap/Card";

import "./Start.css";

const firebaseConfig = {
  apiKey: "AIzaSyAYYMZLkuo6JddLEaArbBFqyvhE93iQ9EQ",
  authDomain: "keep-check-1.firebaseapp.com",
  databaseURL:
    "https://keep-check-1-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "keep-check-1",
  storageBucket: "keep-check-1.appspot.com",
  messagingSenderId: "752201787577",
  appId: "1:752201787577:web:e9dea9cd7f2dbaed631368",
  measurementId: "G-FQKF0S76LX",
};

firebase.initializeApp(firebaseConfig);

const Start = (props) => {
  const { setToken, token, setUser, user, userDb } = props;
  
  useEffect(()=>{
    let ui = null;
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      ui = new auth.AuthUI(firebase.auth());
    } else {
      firebase.app(); 
      ui = auth.AuthUI.getInstance() || new auth.AuthUI(firebase.auth());
    }
    window.email = firebase.auth().currentUser?.email;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        user.getIdTokenResult().then((idTokenResult) => {
          setToken(idTokenResult.token);
        });
      } else setToken(null);
    });

    const uiConfig = {
      signInFlow: "redirect",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ]
    };
    if (!token)
    ui.start("#firebaseui-auth-container", uiConfig);
  },[ token ]);

  const handleSignOut = () => {
    firebase.auth().signOut();
  }

  return (
    <div className="startpage">
      <Card className="startpage" style={{ color: "black" }}>

       {token ? (
          <>
        <Card.Header style={{ width: "100%" }}>Hi {user?.displayName || firebase.auth().currentUser?.email || 'User' }</Card.Header>
        <Card.Body>
          <Card.Title>{userDb?.block ? `Welcome to  ${userDb?.block} block` : 'Oops!!! You are not registered'}</Card.Title>
          <Card.Text>
            {userDb?.block ? `You are registered in ${userDb?.block} block` : 'Please contact your block admin'}
          </Card.Text>
         <Button color="secondary" onClick={handleSignOut}  block>Sign Out!</Button>
          
        </Card.Body>
          </>
        ) : (
          <>
        <Card.Header style={{ width: "100%" }}>Please Sign In</Card.Header>
        <Card.Body>
          <div id="firebaseui-auth-container"></div>
        </Card.Body>
          </>
        )} 

      </Card>
    </div>
  );
};

export default Start;
