import React,{Component} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts'
import {Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
// import { ToWords } from 'to-words';
import convertRupeesIntoWords from 'convert-rupees-into-words';
class Certificate extends Component{
    state={
        isOpen:false,
        expI:'',
        expM:'',
        expW:'',
        assessedAmount:'',
        contigency:''
    }
  
   toggle=()=>{
      const prev = this.state.isOpen;
      this.setState({isOpen:!prev});
  }
  modalEntryHandler = (event,identifier) => {
    this.setState({[identifier]:event.target.value});
  }
  convertToStandardDate = (dateOfCompletion) => {
    var dd = dateOfCompletion.slice(8,10);
    var mm = dateOfCompletion.slice(5,7);
    var yyyy = dateOfCompletion.slice(0,4);
    dateOfCompletion = dd + '/' + mm + '/' + yyyy;
    return dateOfCompletion;
  }
  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  generateCertificate = () => {
    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;

    var dd = {
        content: [
            {
                text: 'Recommendation for and release Final Installment',
                style: 'header'
            },
       
            
            
            {
                style: 'tableExample',
                table: {
                    widths: [150,  '*'],
                    body: [
                        ['Work Register Page No.', `${this.props.data.workRegisterPage[this.props.data.workRegisterPage.length-1]}`],
                        ['Name of Work', `${this.props.data.nameOfWork}`],             
                        ['Head',  `${this.props.data.head}`],
                        ['Name of G.P. / Agency',`${this.props.data.gramPanchayat}`],
                        ['Sanctioned Amount', `${this.props.data.sancAmount}`],
                     ]
                 }
            },
             
           {
                style: 'tableExample',
                table: {
                    widths: [150, '*', 150, '*'],
                    body: [
                        ['Released Amount', `${this.props.data.sancAmount-this.props.data.balance[this.props.data.balance.length-2]}`, 'Assessed Amount' , `${this.state.assessedAmount}`],
                        ['Expenditure incurred', `${this.state.expI}`, 'Balance Amount',`${this.props.data.balance[this.props.data.balance.length-2]}` ],
                    ]
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*',  150],
                    body: [
                        ['Less Contingency', `${this.state.contigency} `],
                        ['Now to Pay\n (Expenditure /Assessed and sanctioned whichever is least)', ` ${Math.min(Math.min(this.props.data.sancAmount,this.state.assessedAmount)-this.state.contigency,this.state.expI)-(this.props.data.sancAmount-this.props.data.balance[this.props.data.balance.length-2])} `],          // ${Math.min(this.state.expI,this.state.assessedAmount)-this.state.contigency-(this.props.data.sancAmount-this.props.data.balance[this.props.data.balance.length-2])}    
                        ['Status of work',  'Completed'],
                    
                     ]
                 }
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', 125, 125, 150],
                    body: [
                        ['Comp.Vide', `MB No. ${this.props.data.mbNo[this.props.data.mbNo.length-1]}`, `Page No. ${this.props.data.page[this.props.data.page.length-1]}` , `Amount ${this.state.assessedAmount} `],
                    ]
                }
            },
            {
                style: 'tableExample',
                table: {
                    widths: [150, '*'],
                    body: [
                        ['Men Days Generated', `${this.props.data.menDays[this.props.data.menDays.length-1]}`],
                        ['Expenditure on materials', `${this.state.expM}`],             
                        ['Expenditure on wages',  `${this.state.expW}`],
                    
                     ]
                 }
            },
               
                `The work is on going / complete, as per recommendation hence amounting Rs. ${Math.min(Math.min(this.props.data.sancAmount,this.state.assessedAmount)-this.state.contigency,this.state.expI)-(this.props.data.sancAmount-this.props.data.balance[this.props.data.balance.length-2])} /-( ${convertRupeesIntoWords(Math.abs(Math.min(Math.min(this.props.data.sancAmount,this.state.assessedAmount)-this.state.contigency,this.state.expI)-(this.props.data.sancAmount-this.props.data.balance[this.props.data.balance.length-2])))} only) is released to G.P. ${this.props.data.gramPanchayat}. `,
                '1. Work up to 3.0 Lac payment recommended on the prior recommendation of Takniki Sahayak. ',
                '2. Photographs(Before after and during) utilisation certificate have obtained.',
                `3. Test Check has been conducted by competent authority and entry to the effect made in M.B. No.${this.props.data.mbNo[this.props.data.mbNo.length-1]} , Page ${this.props.data.page[this.props.data.page.length-1]} (0 to 1.5 Lac: J.E. above 5 Lac A.E. and above 10 Lac by E.E.) Quantity of work found up to the mark M.B. not conuntersigned...`,
                '4. Entries in M.B. / Bill confirm to rates and quantities as per mentioned in HPSR 2009.',
                '5. Earlier released amount has been utilized.',
                '\n\n',
                {
                    text: 'Junior   Engineer',
                    alignment:'right'
                },
                {
                    text:`Dev. Block ${this.capitalizeFirstLetter(window.block)}`,
                    alignment:'right'
                },
                
                'All codal formalities complete documents checked and found in order, Hence Payment recommended ',
                '\n\n',
                '\nBDO                                                                                                                                           Suptt.',
                '\n1. Amount claimed is as per AA and ES (sanctioned order No. .................. Dt.....................).',
                '2. Entries in Bill (Running Final ) and MB No. .................... Page .................. have been checked and found correct.',
                '3. Entry regarding test check by the competent authority has been made in MB No. .................. Page ....................(0 to 1.5 Lac: J.E. above 5 Lac A.E. and above 10 Lac by E.E. ) Quantity of work found up to the mark MB countersigned.',
                '4. Entered in Panchayat wise ledger folio No. .......................',
                '5. Entries in MB /Bill confirm to rates and quantities mentioned in HPSR and expenditure statement submitted by G.P. / Agency executing work. Payment of Rs. .......................Recommended  to be released.',
                {
                    text:'Sr. Astt(Accounts)',
                    alignment:'right'
                },
                {
                    text:`Dev. Block  ${this.capitalizeFirstLetter(window.block)}`,
                    alignment:'right'
                },
                
                
                        
                    
            
            
        ],    
        styles: {
            header: {
                fontSize: 18,
                bold: true
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }
        
    }
    
    
  
    var pdf = pdfMake.createPdf(dd);
    pdf.download();
  }
  
  render(){
    return(
        <div>
    <Button color="info" onClick={this.toggle} block>GENERATE RECOMENDATION FOR AND FINAL INSTALLMENT</Button>
      <Modal isOpen={this.state.isOpen} toggle={this.toggle} >
      <ModalHeader toggle={this.toggle}><div><h2><Badge color="warning">Fill In These Entries</Badge></h2></div></ModalHeader>
     
      <ModalBody>
         <Form>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Expenditure incurred</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'expI')}} value={this.state.expI} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Assessed Amount</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'assessedAmount')}} value={this.state.assessedAmount} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Expenditure on materials</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'expM')}} value={this.state.expM} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Expenditure on wages</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'expW')}} value={this.state.expW} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Less Contigency</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'contigency')}} value={this.state.contigency} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>


        </Form>
     </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.generateCertificate}>Produce Certificate</Button>{' '}
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
        </div>
    );
  }
}



export default Certificate;


