import React, { Component } from "react";
import { Card, CardTitle, CardText, Table } from "reactstrap";

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import "./NewPanchayat.css";
import Spinner from "../Spinner/Spinner";
import axios from "../../Services/axios";
import { createNewPanchayat, deletePanchayat, getPanchayats, getSchemes } from "../../Services/services";

class Newscheme extends Component {
  state = {
    form: {
      panchayat: "",
    },
    validation: {
      panchayat: {
        required: true,
        filled: false,
        changed: false,
        inval: false,
      },
      edit: { required: false, filled: false, changed: false, inval: false },
    },
    panchayats: {},
    loading: false,
    formIsValid: false,
    trySubmitOnce: false,
    result: "not-sent", //failed success
  };

  componentDidMount() {
    this.getAllPanchayats();
  }

  inputChangeHandler = (event, inputIdentifier) => {
    const updatedForm = { ...this.state.form };
    updatedForm[inputIdentifier] = event.target.value;

    const validationForm = { ...this.state.validation };
    const colEntry = { ...this.state.validation[inputIdentifier] };
    colEntry.changed = true;
    colEntry.filled = event.target.value.trim() ? true : false;
    colEntry.inval =
      colEntry.required &&
      !colEntry.filled &&
      (colEntry.changed || this.state.trySubmitOnce);
    validationForm[inputIdentifier] = colEntry;

    this.setState({ form: updatedForm, validation: validationForm });
  };

  validity = (inputIdentifier) => {
    return (
      this.state.validation[inputIdentifier].required &&
      !this.state.validation[inputIdentifier].filled &&
      (this.state.validation[inputIdentifier].changed ||
        this.state.trySubmitOnce)
    );
  };

  getAllPanchayats = () => {
    getPanchayats(window.token, window.block).then((val) => {
      this.setState({ panchayats: val });
    });
  };

  checkFormValidity() {
    let completeValidity = true;
    for (let key in this.state.validation) {
      if (this.state.validation[key].required)
        completeValidity =
          completeValidity && this.state.validation[key].filled;
         }
    Object.keys(this.state.panchayats).forEach((panchayat) => {
      if (this.state.form.panchayat?.toLowerCase() === this.state.panchayats[panchayat].name.toLowerCase())
      completeValidity = false;
    })
    return completeValidity;
  }

  deletePanchayatHandler = (panchayatId) => {
    return () => {
      deletePanchayat(window.token,window.block, panchayatId).then(() => this.getAllPanchayats());
    }
  }

  addNewHandler = () => {
    const formValidity = this.checkFormValidity();
    if (formValidity) {
      this.setState({ loading: true, trySubmitOnce: true });
      const newScheme = {};
      for (let key in this.state.form) {
        newScheme[key] = this.state.form[key];
      }
      createNewPanchayat(window.token, window.block, newScheme['panchayat'])
        .then((response) => {
          this.setState({ result: "success", loading: false });
          
          this.getAllPanchayats();
          setTimeout(()=>{
            this.setState({ result: "not-sent", loading: false });
          },1000);
          // this.props.history.push('/');
        })
        .catch((error) => {
          this.setState({ result: "failed", loading: false });
        });


    } else {
      this.setState({ trySubmitOnce: true });
    }
  };

  render() {
    return (
      <div className="container inputArea">
        <div className="row">
          <div className="col-12">
            <Card body className="card">
              <CardTitle>
                <h2>Add New Panchayat</h2>
              </CardTitle>
              <FormText color="muted">Here you can add new Panchayat</FormText>

              <Form>
                <hr />

                <FormGroup row>
                  <Label for="Panchayat" sm={2}>
                    Panchayat Name
                  </Label>
                  <Col sm={5}>
                    <Input
                      invalid={this.validity("panchayat")}
                      value={this.state.value}
                      onChange={(event) =>
                        this.inputChangeHandler(event, "panchayat")
                      }
                      type="text"
                      name="panchayat"
                      placeholder="Eg. panchayat"
                    />
                  </Col>
                  <Col sm={4}>
                    <Button
                      disabled={this.state.result === "success"}
                      onClick={this.addNewHandler}
                      color={
                        this.state.result === "not-sent"
                          ? "primary"
                          : this.state.result === "success"
                          ? "success"
                          : "danger"
                      }
                    >
                      Add New Panchayat
                    </Button>
                  </Col>
                  <Col sm={1}>
                    {this.state.loading ? <Spinner /> : <div></div>}
                  </Col>
                </FormGroup>

                {/* <FormGroup row>
                  <Label for="editby" sm={2}>
                    Created By
                  </Label>
                  <Col sm={7}>
                    <Input
                      invalid={this.validity("edit")}
                      type="text"
                      name="status"
                      value="Name of Employee"
                    />
                  </Col>
                  <Col sm={2}>
                    <Button
                      disabled={this.state.result === "success"}
                      onClick={this.addNewHandler}
                      color={
                        this.state.result === "not-sent"
                          ? "primary"
                          : this.state.result === "success"
                          ? "success"
                          : "danger"
                      }
                    >
                      Add
                    </Button>
                  </Col>
                  <Col sm={1}>
                    {this.state.loading ? <Spinner /> : <div></div>}
                  </Col>
                </FormGroup> */}
              </Form>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name of panchayat</th>
                    <th>Delete</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(this.state.panchayats).map(
                    (panchayat, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{this.state.panchayats[panchayat].name}</td>
                        <td>
                          <Button disabled={Object.keys(this.state.panchayats).length === 1} onClick={this.deletePanchayatHandler(panchayat)} color="danger">Delete</Button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default Newscheme;
