import React,{Component} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts'
import {Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col,  Table , Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import intToNumber from '../Converter/numberToWords';
import  converter from 'number-to-words';
import convertRupeesIntoWords from 'convert-rupees-into-words';
// import { ToWords } from 'to-words';

class Certificate extends Component{
    state={
        isOpen:false,
        estimatedCost:'',
       
    }

    toggle=()=>{
        const prev = this.state.isOpen;
        this.setState({isOpen:!prev});
    }

    modalEntryHandler = (event,identifier) => {
        this.setState({[identifier]:event.target.value});
      }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    convertToStandardDate = (dateOfCompletion) => {
        var dd = dateOfCompletion.slice(8,10);
        var mm = dateOfCompletion.slice(5,7);
        var yyyy = dateOfCompletion.slice(0,4);
        dateOfCompletion = dd + '/' + mm + '/' + yyyy;
        return dateOfCompletion;
    }
    generateCertificate = () => {
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        
        var releaseDate = this.convertToStandardDate(this.props.data.dateOfRelease[0]);
        var sancDate = this.convertToStandardDate(this.props.data.sancDate);
        // const toWords = new ToWords();
        // let amount = toWords.convert(this.props.data.releaseAmount[0], { currency: true });
        var amount = convertRupeesIntoWords(Math.abs(this.props.data.releaseAmount[this.props.data.releaseAmount.length-1]));
    
        let releaseNo =  converter.toWordsOrdinal(this.props.data.releaseAmount.length);
        

        
        var dd = {
            content: [
                {
                    text: `Recommendation for and Release of ${releaseNo}  Payment\n (or fund transfer to Gram Panchayat )\n`,
                    style: 'header'
                },
        '\n',
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*', '*', '*', '*'],
                        body: [
                            ['Work Register Page No.', `${this.props.data.workRegisterPage[0]}`, 'Gram Panchayat', `${this.props.data.gramPanchayat}`],
                            ['Name of work' ,{colSpan:3,text:`${this.props.data.nameOfWork}`}],
                            ['Head',`${this.props.data.head}`, 'Name of GP/Agency', `${this.props.data.gramPanchayat}`],
                            ['Sanctioned Amount ' ,{colSpan:3,text:`${this.props.data.sancAmount}`}],
                            ['Released Amount',`${this.props.data.releaseAmount[this.props.data.releaseAmount.length-1]}`,'Estimated cost',`${this.state.estimatedCost}`]
                        ]
                    }
        
                },
                    `\nThis work is going as per specifications and as per estimate ,Hence ${releaseNo} / final installment in advance amounting Rs. ${this.props.data.releaseAmount[this.props.data.releaseAmount.length-1]} /-( ${amount} only) released to G.P. ${this.props.data.gramPanchayat}.`,
                    'Estimate has been prepared in advance keeping in view the site requirements',
                    '1. Technical Sanction has been prvided by competent authority. ',
                    '2. No expenditure for the sanctioned work has been incurred.',
                    'OR',
                    `Expenditure amounting ...................... under Head ................. has been incurred in Year.............. `,
                    '3. Work is being executed at place and purpose it is sanctioned for . ',
                    '4. NOC and land papers have been obtained by executing agency ',
                    '. AA and ES has been obtained from the competent authority.',
                    `(Sanctioned order No.${this.props.data.sancNo} Dated ${sancDate} )`,
                    '\n',
                    '\n', 
                     {
                         text: 'Junior    Engineer',alignment:'right'
                               
                     },
                     { 
                         text: `Dev. Block ${this.capitalizeFirstLetter(window.block)}`,alignment:'right'
                     },
                    
                     '\n',
                     'All codal formalities complete checked and found in order hence \n Payment Recommended.  ',
                     '\n',
                     '\n',
                     '\nBDO                                                                                                                                    Suptt. ',
                     `\n1. Amount claimed is as per AA and ES (Sanctioned order No. ...........)`,
                     '2. Entered in Panchayat wise ledger folio No. ................',
                     `3. Payment of Rs.......................... Recommended .`,
                     '\n',
                     '\n',
                     ,
                     {
                        text:'Sr Astt(Accounts)' ,alignment:'right'                     
                    }

                ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true
                },
                subheader: {
                    fontSize: 15,
                    bold: true
                },
                quote: {
                    italics: true   
                },
                small: {
                    fontSize: 8
                }
            }
            
        }
        var pdf = pdfMake.createPdf(dd);
        pdf.download();
    }
 
    render(){
     
        // let releaseNo = '1';
        // return(
        //     <Button color="secondary" onClick={this.generateCertificate}>Generate RELEASE CERTIFICATE</Button>
        // );


        return(
            <div>
            <Button color="info" onClick={this.toggle} block>GENERATE RELEASE CERTIFICATE</Button>
      <Modal isOpen={this.state.isOpen} toggle={this.toggle} style={{"max-width": "50%"}} >
      <ModalHeader toggle={this.toggle}><div><h2><Badge color="warning">Fill In These Entries</Badge></h2></div></ModalHeader>
     
      <ModalBody>
         <Form>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Estimated cost</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'estimatedCost')}} value={this.state.estimatedCost} id="exampleEmail" placeholder="Eg 20000" />
                </Col>
            </FormGroup>
           </Form>



     </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={this.generateCertificate}>Produce Certificate</Button>{' '}
        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
        </div>
        );
   


    }

}


export default Certificate;