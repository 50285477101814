import React,{Component} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts'
import {Button} from 'reactstrap';
import { capitalizeFirstLetter } from '../../Services/helper';

class Certificate extends Component{
  convertToStandardDate = (dateOfCompletion) => {
    var dd = dateOfCompletion.slice(8,10);
    var mm = dateOfCompletion.slice(5,7);
    var yyyy = dateOfCompletion.slice(0,4);
    dateOfCompletion = dd + '/' + mm + '/' + yyyy;
    return dateOfCompletion;
  }
  generateCertificate = () => {
    const {vfs} = vfsFonts.pdfMake;
    pdfMake.vfs = vfs;
    var today = new Date();
    
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;

    var dateOfCompletion = this.convertToStandardDate(this.props.data.dateOfRelease[this.props.data.dateOfRelease.length-1]);
    var sanctionDate = this.convertToStandardDate(this.props.data.sancDate);

    var dd = {
    content: [
        {
        text: ' Utilization/Completion  Certificate \n\n',
        style: 'header',
        alignment: 'center'
        },
      ' ',
      {
        text: `Work / Scheme ID: ${this.props.data.workID}                                        Name of Panchayat :${this.props.data.gramPanchayat} `,
        style: 'subheader'
      },
      ' ',
      {
        text: `1. Name of Work/Scheme : ${this.props.data.nameOfWork}`,
        style: 'subheader'
      },
      ' ',
      
      {
        text: `2. Head : ${this.props.data.head}`,
        style: 'subheader'
      },
      ' ',
          {
        text: `3. Financial Year : ${this.props.data.financialYear}` ,
        style: 'subheader'
      
          },
      ' ',
          {
        text: `4. Sanction No. : ${this.props.data.sancNo}`,
        style: 'subheader'
      },
      ' ',
          {
        text: `5. Sanction Date : ${sanctionDate}`,
        style: 'subheader'
      },
      ' ',
      {
        text: `6. Sanction Amount : ${this.props.data.sancAmount}`,
        style: 'subheader'
      },
      ' ',	
        
          {
        text: `7. Date of Completion : ${dateOfCompletion}`,
        style: 'subheader'
      },
      ' ',
          {
        text: `8. Expenditure : ${this.props.data.sancAmount}`,
        style: 'subheader'
      },
      ' ',
          {
        text: `9. Date of issue of utilization certificate : ${today}`,
        style: 'subheader'
      },
      ' ',
          {
        text: `10. MB No. : ${this.props.data.mbNo[this.props.data.mbNo.length-1]}                             Page :${this.props.data.page[this.props.data.page.length-1]}`,
        style: 'subheader'
      },
      ' ',
          {
        text: `11. Mendays generated : ${this.props.data.menDays[this.props.data.menDays.length-1]} `,
        style: 'subheader'
      },
      ' ',
          {
        text: `12. Work Ragister Page No.  :  ${this.props.data.workRegisterPage[this.props.data.workRegisterPage.length-1]}`,
        style: 'subheader'
      },
      ' ',
          {
        text: 'Certified that the work has been completed / sanctioned amount utilized as per estimate in proposed work',
        style: 'subheader'
      },
      ' ',' ',' ',' ',' ',' ',' ',
      
          {
        text: 'Junior Engineer                                                                                         Block Development Officer ',
                style: 'subheader'
          },
  
      
         {
        text: `Dev. Block ${capitalizeFirstLetter(window.block)}                                                                                      Development Block ${capitalizeFirstLetter(window.block)}  `,
                style: 'subheader',
                pageBreak: 'after'
          }
      
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true
      },
      bigger: {
        fontSize: 15,
        italics: true
      }
    }
    
  }
  
    var pdf = pdfMake.createPdf(dd);
    pdf.download();
  }
  
  render(){
    return(
      <Button color="primary" onClick={this.generateCertificate}>GENERATE UTILIZATION CERTIFICATE</Button>
    );
  }
}



export default Certificate;


