import Print from "../Print/Print";
import xlsx from "json-as-xlsx";
import { useState } from "react";
import { Button } from "reactstrap";

const Sheet = (props) => {
  const [labels, setLabels] = useState([]);
  const [labelsName, setLabelsName] = useState([]);
  const [printing, setPrinting] = useState(false);

  const printingChangeHandler = () => {
    setPrinting((print) => !print);
  };

  const labelChangeHandler = (newLabels, newLabelsName) => {
    setLabels(newLabels);
    setLabelsName(newLabelsName);
  };

  const getColumnsHeaders = () => {
    const headers = labels.map((label, index) => {
      return { label: labelsName[index], value: label };
    });
    return headers;
  };

  const getColumns = () => {
    const colmns = props.dataInput.map((data, index) => {
      const column = {};
      labels.forEach((label) => {
        column[label] = Array.isArray(data[label])
          ? data[label][data[label].length - 1]
          : data[label];
      });
      return column;
    });
    return colmns;
  };

  const printNow = () => {
    const data = [
      {
        sheet: "Data",
        columns: getColumnsHeaders(),
        content: getColumns(),
      },
    ];

    const settings = {
      fileName: "MySpreadsheet",
    };
    xlsx(data, settings);
  };

  if (props.dataInput?.length > 0) {
    return (
      <div>
        <Button block onClick={printingChangeHandler}>
          {printing ? "CANCEL XLSC PRINT" : "GENERATE EXCEL REPORT"}
        </Button>
        {printing ? (
          <Print
            labelChangeHandler={labelChangeHandler}
            data={props.dataInput[0]}
          />
        ) : null}
        {printing ? (
          <Button block onClick={printNow} className="btn btn-warning">
            Print
          </Button>
        ) : null}
      </div>
    );
  } else return <div>NOTHING TO PRINT</div>;
};

export default Sheet;
