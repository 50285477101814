import React, { Component } from "react";
import axios from "../../Services/axios";
import Sheet from "../Sheet/Sheet";
import FilterNav from "../FilterNav/FilterNav";
import { Table, Badge } from "reactstrap";
import SanctionOrder from "../SanctionOrder/SanctionOrder";
import UtilizationCertificate from "../Certificate/UtilizationCertificate";

class Tables extends Component {
  state = {
    schemes: [],
    loading: false,
    dataLoaded: "notyet",
    activeField: "workID",
    mode: "singleValue", //or range
    type: "text",
    index: -1,
    filterData: {
      workID: "",
      head: "",
      nameOfWork: "",
      gramPanchayat: "",
      sancNo: "",
      financialYear: ["", "", ""],
      sancDate: ["", "", ""], //after before on
      sancAmount: ["", "", ""], // greater less equal
      dateOfRelease: ["", "", ""],
      dateOfCompletion: ["", "", ""],
    },
  };
  componentDidMount() {
    axios
      .get(`blocks/${window.block}/schemes.json?auth=` + window.token)
      .then((res) => {
        this.setState({ loading: true });
        const fetchedOrders = [];
        for (let key in res.data) {
          fetchedOrders.push({
            ...res.data[key],
            id: key,
          });
        }
        this.setState({
          loading: false,
          schemes: fetchedOrders,
          dataLoaded: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false, dataLoaded: "error" });
      });
  }

  clearAllFilters = () => {
    const filterData = {
      workID: "",
      head: "",
      nameOfWork: "",
      gramPanchayat: "",
      sancNo: "",
      financialYear: ["", "", ""],
      sancDate: ["", "", ""], //after before on
      sancAmount: ["", "", ""], // greater less equal
      dateOfRelease: ["", "", ""],
      dateOfCompletion: ["", "", ""],
    };
    this.setState({ filterData: filterData });
  };

  activeFieldModeHandler = (field, NewMode, type, index = -1) => {
    this.setState({
      activeField: field,
      mode: NewMode,
      type: type,
      index: index,
    });
  };

  inputHandler = (event) => {
    const now = this.state.filterData;
    let value = this.state.filterData[this.state.activeField];
    if (this.state.index > -1) {
      value[this.state.index] = event.target.value;
    } else {
      value = event.target.value;
    }
    now[this.state.activeField] = value;
    this.setState({ filterData: now });
  };
  dualInputHandler = (event, index) => {
    const now = this.state.filterData;
    let value = this.state.filterData[this.state.activeField];
    value[index] = event.target.value;
    now[this.state.activeField] = value;
    this.setState({ filterData: now });
  };

  extractStartYear = (str) => {
    return parseInt(str.slice(0, 4));
  };

  render() {
    let content = this.state.schemes;

    if (this.props.type !== "All")
      content = this.state.schemes.filter((work) => {
        if (
          work?.status &&
          work.status[work.status.length - 1] ==
            (this.props.type === "Not Started" ? "" : this.props.type)
        )
          return true;
        else return false;
      });

    content = content.filter((work) => {
      let val = true;
      if (this.state.filterData.workID !== "")
        val =
          val &&
          work.workID
            .toLowerCase()
            .startsWith(this.state.filterData.workID.toLowerCase());

      if (this.state.filterData.head !== "")
        val =
          val &&
          work.head
            .toLowerCase()
            .startsWith(this.state.filterData.head.toLocaleLowerCase());

      if (this.state.filterData.nameOfWork !== "")
        val =
          val &&
          work.nameOfWork
            .toLowerCase()
            .startsWith(this.state.filterData.nameOfWork.toLocaleLowerCase());

      if (this.state.filterData.gramPanchayat !== "")
        val =
          val &&
          work.gramPanchayat
            .toLowerCase()
            .startsWith(this.state.filterData.gramPanchayat.toLowerCase());

      if (this.state.filterData.sancNo !== "")
        val = val && work.sancNo === this.state.filterData.sancNo;

      if (this.state.filterData.financialYear[0] !== "")
        val =
          val &&
          this.extractStartYear(work.financialYear) >=
            this.extractStartYear(this.state.filterData.financialYear[0]);

      if (this.state.filterData.financialYear[1] !== "")
        val =
          val &&
          this.extractStartYear(work.financialYear) <=
            this.extractStartYear(this.state.filterData.financialYear[1]);

      if (this.state.filterData.financialYear[2] !== "")
        val =
          val &&
          this.extractStartYear(work.financialYear) ===
            this.extractStartYear(this.state.filterData.financialYear[2]);

      if (this.state.filterData.sancDate[0] !== "")
        val = val && work.sancDate >= this.state.filterData.sancDate[0];

      if (this.state.filterData.sancDate[1] !== "")
        val = val && work.sancDate <= this.state.filterData.sancDate[1];

      if (this.state.filterData.sancDate[2] !== "")
        val = val && work.sancDate === this.state.filterData.sancDate[2];

      if (this.state.filterData.sancAmount[0] !== "")
        val = val && work.sancAmount >= this.state.filterData.sancAmount[0];

      if (this.state.filterData.sancAmount[1] !== "")
        val = val && work.sancAmount <= this.state.filterData.sancAmount[1];

      if (this.state.filterData.sancAmount[2] !== "")
        val = val && work.sancAmount === this.state.filterData.sancAmount[2];

      if (this.state.filterData.dateOfRelease[0] !== "")
        val =
          val &&
          work.dateOfRelease[0] >= this.state.filterData.dateOfRelease[0];

      if (this.state.filterData.dateOfRelease[1] !== "")
        val =
          val &&
          work.dateOfRelease[0] <= this.state.filterData.dateOfRelease[1];

      if (this.state.filterData.dateOfRelease[2] !== "")
        val =
          val &&
          work.dateOfRelease[0] === this.state.filterData.dateOfRelease[2];

      if (this.state.filterData.dateOfCompletion[0] !== "")
        val =
          val &&
          work?.status &&
          work.dateOfRelease[work.status.length - 1] >=
            this.state.filterData.dateOfCompletion[0];

      if (this.state.filterData.dateOfCompletion[1] !== "")
        val =
          val &&
          work?.status &&
          work.dateOfRelease[work.status.length - 1] <=
            this.state.filterData.dateOfCompletion[1];

      if (this.state.filterData.dateOfCompletion[2] !== "")
        val =
          val &&
          work?.status &&
          work.dateOfRelease[work.status.length - 1] ===
            this.state.filterData.dateOfCompletion[2];

      return val;
    });

    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "INR",
    });

    // let totalSancAmount = content.reduce((acc,cur)=>acc+ parseFloat(cur.sancAmount),0);

    let totalSancAmount = formatter.format(
      content.reduce((acc, cur) => acc + parseFloat(cur.sancAmount), 0)
    );

    let totalBalance = formatter.format(
      content.reduce(
        (acc, cur) =>
          acc +
          parseFloat(
            cur?.balance?.length ? cur.balance[cur.balance.length - 1] : 0
          ),
        0
      )
    );

    return (
      <div className="table-external">
        <h2>
          {this.state.dataLoaded === "success" ? (
            <Badge color="success" pill>
              Data Loaded
            </Badge>
          ) : this.state.dataLoaded === "notyet" ? (
            <Badge color="warning" pill>
              No Data Loaded Yet :({" "}
            </Badge>
          ) : (
            <Badge color="danger" pill>
              Error Loading Data :({" "}
            </Badge>
          )}
        </h2>
        {this.props.type === "Not Started" ? (
          <SanctionOrder content={content} />
        ) : null}
        {this.props.type === "Completed" ? (
          <UtilizationCertificate content={content} />
        ) : null}
        <hr></hr>
        <Sheet dataInput={content} />

       
        <FilterNav
          filterData={this.state.filterData}
          activeField={this.state.activeField}
          type={this.state.type}
          mode={this.state.mode}
          index={this.state.index}
          inputHandler={this.inputHandler}
          dualInputHandler={this.dualInputHandler}
          activeFieldModeHandler={this.activeFieldModeHandler}
          clearAllFilters={this.clearAllFilters}
        />

        {this.props.type === "Not Started" ? (
          <Table striped hover responsive="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Work ID</th>
                <th>Head</th>
                <th>Name of work</th>
                <th>Gram Panchayat</th>
                <th>Sanction No</th>
                <th>Sanction Date</th>
                <th>Sanction Amount</th>
                <th>Financial Year</th>
              </tr>
            </thead>

            <tbody>
              {content.map((work, index) => (
                <tr
                  key={work.id}
                  onClick={() => {
                    this.props.expander(work.id);
                  }}
                >
                  <th>{index + 1}</th>
                  <td>{work.workID}</td>
                  <td>{work.head}</td>
                  <td>{work.nameOfWork}</td>
                  <td>{work.gramPanchayat}</td>
                  <td>{work.sancNo}</td>
                  <td>{work.sancDate}</td>
                  <td>{work.sancAmount}</td>
                  <td>{work.financialYear}</td>
                </tr>
              ))}
              <tr>
                <th>Total</th>
                <th> </th>
                <th> </th>
                <th> </th>
                <th> </th>
                <th> </th>
                <th> </th>
                <th>{totalSancAmount}</th>
                <th> </th>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div className="table">
            <Table striped hover responsive="sm">
             
              <thead>
                <tr>
                  <th>#</th>
                  <th>Work ID</th>
                  <th>Head</th>
                  <th>Name of work</th>
                  <th>Gram Panchayat</th>
                  <th>Sanction No</th>
                  <th>Sanction Date</th>
                  <th>Sanction Amount</th>
                  <th>Financial Year</th>

                  <th>MB No</th>
                  <th>Page</th>
                  <th>WRP</th>
                  <th>M.Days</th>

                  <th>Date of release</th>
                  <th>Release Amount</th>
                  <th>Balance</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                {content.map((work, index) => (
                  <tr
                    key={work.id}
                    onClick={() => {
                      this.props.expander(work.id);
                    }}
                  >
                    <th>{index + 1}</th>
                    <td>{work.workID}</td>
                    <td>{work.head}</td>
                    <td>{work.nameOfWork}</td>
                    <td>{work.gramPanchayat}</td>
                    <td>{work.sancNo}</td>
                    <td>{work.sancDate}</td>
                    <td>{work.sancAmount}</td>
                    <td>{work.financialYear}</td>
                    <td>
                      {work?.mbNo?.length
                        ? work.mbNo[work.mbNo.length - 1]
                        : "NA"}
                    </td>
                    <td>
                      {work?.page?.length
                        ? work.page[work.page.length - 1]
                        : "NA"}
                    </td>
                    <td>
                      {work?.workRegisterPage?.length
                        ? work.workRegisterPage[
                            work.workRegisterPage.length - 1
                          ]
                        : "NA"}
                    </td>
                    <td>
                      {work?.menDays?.length
                        ? work.menDays[work.menDays.length - 1]
                        : "NA"}
                    </td>
                    <td>
                      {work?.dateOfRelease?.length
                        ? work.dateOfRelease[work.dateOfRelease.length - 1]
                        : "NA"}
                    </td>
                    <td>
                      {work?.releaseAmount?.length
                        ? work.releaseAmount[work.releaseAmount.length - 1]
                        : "NA"}
                    </td>
                    <td>
                      {work?.balance?.length
                        ? work.balance[work.balance.length - 1]
                        : "NA"}
                    </td>
                    <td>
                      {work?.status?.length
                        ? work.status[work.status.length - 1]
                        : "NA"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th>Total</th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th>{totalSancAmount}</th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th> </th>
                  <th>{totalBalance}</th>
                  <th> </th>
                </tr>
              </tbody>
            </Table>
          </div>
        )}

        <h2>
          Total Sanction Amount :{" "}
          <Badge color="secondary">{totalSancAmount}</Badge>{" "}
        </h2>
       
      </div>
    );
  }
}

export default Tables;
