import React,{Component} from 'react';
import { Card, CardTitle, CardText,Button} from 'reactstrap';
import { Table ,Input,Col,Form, FormGroup, Label, FormText } from 'reactstrap';
import Spinner from '../Spinner/Spinner';
import './Edit.css';
import axios from '../../Services/axios';
import Certificate from '../Certificate/Certificate';
import FirstRelease from '../FirstRelease/FirstRelease';
import FinalRelease from '../FinalRelease/FinalRelease';
import Hindi from '../Hindi/Hindi';
import DeleteWork from '../DeleteWork/DeleteWork';
class Edit extends Component{
    state = {
        scheme:null,
        head:null,
        nameOfWork:null,
        gramPanchayat:null,
        sancNo:null,
        sancDate:null,
        financialYear:null,
        mbNo:null,
        page:null,
        workRegisterPage:null,
        menDays:null,
        description:null,

        editing:false,
        holdStore : {
            mbNo:'',
            page:'',
            workRegisterPage:'',
            menDays:'',
            dateOfRelease:'',
            releaseAmount:'',
            balance:'',
            status:'',
            edit:''

        },
        result: 'not-sent' //failed success
    }
    componentDidMount(){
        if(this.props.entryID!==null){
            axios.get(`blocks/${window.block}/schemes/${this.props.entryID}.json?auth=${window.token}`).then(res => {
                this.setState({
                    loading:false,scheme:res.data,
                    head:res.data.head,
                    nameOfWork:res.data.nameOfWork,
                    gramPanchayat:res.data.gramPanchayat,
                    sancNo:res.data.sancNo,
                    sancDate:res.data.sancDate,
                    financialYear:res.data.financialYear,
                    mbNo:res.data.mbNo,
                    page:res.data.page,
                    workRegisterPage:res.data.workRegisterPage,
                    menDays:res.data.menDays,
                    description:res.data.description
                });

            }).catch(err => {
                this.setState({loading:false});
            });
        }
        else{
        }
    }
    editHandler = (val) => {
        this.setState({editing:val});
    }
    inputChangeHandler = (event,inputIdentifier) => {


        const hold = {...this.state.holdStore};
        hold[inputIdentifier] = event.target.value;
        hold.edit = window.nameOfEmp || window.email || 'NO_NAME';
           if(inputIdentifier==='releaseAmount'){
            let balance = this.state.scheme.sancAmount;
            if(this.state.scheme.dateOfRelease[0].trim())
                balance = this.state.scheme.balance[this.state.scheme.balance.length-1];
            
            balance = (balance - event.target.value).toString();
            hold.balance = balance;
        }

        this.setState({holdStore:hold});
    }
    checkFormValidity = (release) => {
        let validity = true;

        for(let key in this.state.holdStore){
            if((key === 'dateOfRelease' || key === 'releaseAmount' || key === 'status' || (key === 'workRegisterPage') && release==='first') || (this.state.holdStore.status === 'Completed'))
                validity = validity && (this.state.holdStore[key].trim()?true:false);
        }   
        return validity;
    }
    updateDataHander = () => {
        let release = this.state.scheme.dateOfRelease[0].trim()?'notFirst':'first'; 
        const formValidity = this.checkFormValidity(release);
        if(formValidity){
            this.setState({loading:true});
            const newScheme = {...this.state.scheme};
            
            let mbNo = [...this.state.scheme.mbNo];
            let page = [...this.state.scheme.page];
            let workRegisterPage = [...this.state.scheme.workRegisterPage];
            let menDays = [...this.state.scheme.menDays];
            
            let dateOfRelease = [...this.state.scheme.dateOfRelease];
            let releaseAmount = [...this.state.scheme.releaseAmount];
            let balance = [...this.state.scheme.balance];
            let status = [...this.state.scheme.status];
            let edit = [...this.state.scheme.edit];

            

            if(dateOfRelease[0].trim()? true:false){
                mbNo.push(this.state.holdStore.mbNo);
                page.push(this.state.holdStore.page);
                workRegisterPage.push(this.state.holdStore.workRegisterPage);
                menDays.push(this.state.holdStore.menDays);

                dateOfRelease.push(this.state.holdStore.dateOfRelease);
                releaseAmount.push(this.state.holdStore.releaseAmount);
                balance.push(this.state.holdStore.balance);
                status.push(this.state.holdStore.status);
                edit.push(this.state.holdStore.edit);
            }
            else
            {

                mbNo[0] = this.state.holdStore.mbNo;
                page[0] = this.state.holdStore.page;
                workRegisterPage[0] = this.state.holdStore.workRegisterPage;
                menDays[0] = this.state.holdStore.menDays;
 
                dateOfRelease[0] = this.state.holdStore.dateOfRelease;
                releaseAmount[0] = this.state.holdStore.releaseAmount;
                balance[0] = this.state.holdStore.balance;
                status[0] = this.state.holdStore.status;
                edit[0] = this.state.holdStore.edit;
 
            }            
            
            newScheme.mbNo = mbNo;
            newScheme.page = page;
            newScheme.workRegisterPage = workRegisterPage;
            newScheme.menDays = menDays;

            newScheme.dateOfRelease = dateOfRelease;
            newScheme.releaseAmount = releaseAmount;
            newScheme.balance = balance;
            newScheme.status = status;
            newScheme.edit = edit;

      
            axios.patch(`blocks/${window.block}/schemes/${this.props.entryID}.json?auth=${window.token}`, newScheme).then(response => {
            this.setState({ result: 'success', loading: false });
            setTimeout(()=>{this.props.expander(null)}, 3000);
                
            }).catch(error => {
            this.setState({ result: 'failed', loading: false });
            });

        }
    }
  


    render(){
        let tableEntries = null;
        if(this.state.scheme!==null){
            if(this.state.editing){
                if(this.state.scheme.dateOfRelease[0].trim()) //Normal case... 2nd or later installment
                {
                    let mainData = this.state.scheme.balance.map((entry,index) => {
                        if(index===0){
                            return(
                        <tr key={index}>
                            <th>{this.state.scheme.workID} </th>
                            <th>{this.state.scheme.sancAmount} </th>
                            <th>{this.state.scheme.mbNo[0]}</th>
                            <th>{this.state.scheme.page[0]}</th>
                            <th>{this.state.scheme.workRegisterPage[0]}</th>
                            <th>{this.state.scheme.menDays[0]}</th>
                            <th>{this.state.scheme.dateOfRelease[0]} </th>
                            <th>{this.state.scheme.releaseAmount[0]} </th>
                            <th>{this.state.scheme.balance[0]} </th>
                            <th>{this.state.scheme.status[0]} </th>
                            <th>{this.state.scheme.edit[0]}</th>
                        </tr> 
                            ); 
                        }
                        else
                        return(
                            <tr key={index} >
                                <th> </th>
                                <th> </th>
                                <th>{this.state.scheme.mbNo[index]} </th>
                                <th>{this.state.scheme.page[index]} </th>
                                <th>{this.state.scheme.workRegisterPage[index]} </th>
                                <th>{this.state.scheme.menDays[index]} </th>
                                <th>{this.state.scheme.dateOfRelease[index]} </th>
                                <th>{this.state.scheme.releaseAmount[index]} </th>
                                <th>{this.state.scheme.balance[index]} </th>
                                <th>{this.state.scheme.status[index]} </th>
                                <th>{this.state.scheme.edit[index]}</th>
                            </tr>
                        );
                    });
                    let EditSection = (
                            <tr key={'inputField'}>
                             
                                <th> </th>
                                <th> </th>
                              
                                <th><Input invalid={this.state.holdStore.mbNo.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'mbNo')} value={this.state.holdStore.mbNo} type="number"  /></th>
                                <th><Input invalid={this.state.holdStore.page.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'page')} value={this.state.holdStore.page} type="number"   /></th>
                                <th><Input invalid={this.state.holdStore.workRegisterPage.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'workRegisterPage')} value={this.state.holdStore.workRegisterPage} type="number"  /></th>
                                <th><Input invalid={this.state.holdStore.menDays.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'menDays')} value={this.state.holdStore.menDays} type="number"   /></th>
                              
                                <th><Input invalid={this.state.holdStore.dateOfRelease.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'dateOfRelease')} value={this.state.holdStore.dateOfRelease} type="date"  /></th>
                                <th><Input invalid={this.state.holdStore.releaseAmount.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'releaseAmount')} value={this.state.holdStore.releaseAmount} type="number"   /></th>
                                <th><Input invalid={ this.state.holdStore.balance.trim()?false:true }  value={this.state.holdStore.balance} type="number"  readOnly  /></th>
                                
                                {/* <th><Input invalid={  this.state.holdStore.status.trim()?false:true  } onChange={(event)=> this.inputChangeHandler(event,'status')} value={this.state.holdStore.status} type="text"   /></th> */}
                                <th>
                                    <Input invalid={  this.state.holdStore.status.trim()?false:true  } onChange={(event)=> this.inputChangeHandler(event,'status')} value={this.state.holdStore.status} type="select"  style={{'min-width':'130px'}}>      <option>Select One</option>
                                        {this.state.scheme.status[this.state.scheme.status.length-1]!=='Completed'?<option>In Progress</option>:null}
                                        {/* <option>Not Started</option> */}
                                        <option>Completed</option>
                                    </Input></th>
                                <th><Input invalid={ this.state.holdStore.edit.trim()?false:true }  value={this.state.holdStore.edit} type="text"   readOnly  /></th>
                          
                                </tr>

                    );
                    tableEntries = (mainData );
                    tableEntries.push(EditSection);
                }
                else // not released yet.. ie first installment
                {
                    tableEntries = (
                        <tr>
                            <th>{this.state.scheme.workID} </th>
                            <th>{this.state.scheme.sancAmount} </th>
                            <th><Input invalid={this.state.holdStore.mbNo.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'mbNo')} value={this.state.holdStore.mbNo} type="number"  /></th>
                            <th><Input invalid={this.state.holdStore.page.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'page')} value={this.state.holdStore.page} type="number"   /></th>
                            <th><Input invalid={this.state.holdStore.workRegisterPage.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'workRegisterPage')} value={this.state.holdStore.workRegisterPage} type="number"  /></th>
                            <th><Input invalid={this.state.holdStore.menDays.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'menDays')} value={this.state.holdStore.menDays} type="number"   /></th>
                                   
                            <th><Input invalid={this.state.holdStore.dateOfRelease.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'dateOfRelease')} value={this.state.holdStore.dateOfRelease} type="date"  /></th>
                            <th><Input invalid={this.state.holdStore.releaseAmount.trim()?false:true} onChange={(event)=> this.inputChangeHandler(event,'releaseAmount')} value={this.state.holdStore.releaseAmount} type="number"   /></th>
                            <th><Input invalid={ this.state.holdStore.balance.trim()?false:true }  value={this.state.holdStore.balance} type="number"   readOnly  /></th>
                           
                            {/* <th><Input invalid={  this.state.holdStore.status.trim()?false:true  } onChange={(event)=> this.inputChangeHandler(event,'status')} value={this.state.holdStore.status} type="text"   /></th> */}
                            <th>
                                    <Input invalid={  this.state.holdStore.status.trim()?false:true  } onChange={(event)=> this.inputChangeHandler(event,'status')} value={this.state.holdStore.status} type="select" style={{'min-width':'130px'}} >      <option>Select One</option>
                                        <option>In Progress</option>
                                        {/* <option>Not Started</option> */}
                                        {/* <option>Completed</option> */}
                                    </Input>
                            </th>
                            <th><Input invalid={ this.state.holdStore.edit.trim()?false:true }  value={this.state.holdStore.edit} type="text"   readOnly  /></th>
                          
                        </tr> 
            
                    );
                    
                }
            }
            else //Not Editing...
            {
                tableEntries = this.state.scheme.balance.map((entry,index) => {
                if(index===0){
                    return(
                <tr key={index}>
                    <th>{this.state.scheme.workID} </th>
                    <th>{this.state.scheme.sancAmount} </th>
                    <th>{this.state.scheme.mbNo[0]}</th>
                    <th>{this.state.scheme.page[0]}</th>
                    <th>{this.state.scheme.workRegisterPage[0]}</th>
                    <th>{this.state.scheme.menDays[0]}</th>
                    <th>{this.state.scheme.dateOfRelease[0]} </th>
                    <th>{this.state.scheme.releaseAmount[0]} </th>
                    <th>{this.state.scheme.balance[0]} </th>
                    <th>{this.state.scheme.status[0]} </th>
                    <th>{this.state.scheme.edit[0]}</th>
                </tr> 
                    ); 
                }
                else
                return(
                    <tr key={index} >
                        <th> </th>
                        <th> </th>
                        <th>{this.state.scheme.mbNo[index]} </th>
                        <th>{this.state.scheme.page[index]} </th>
                        <th>{this.state.scheme.workRegisterPage[index]} </th>
                        <th>{this.state.scheme.menDays[index]} </th>
                        <th>{this.state.scheme.dateOfRelease[index]} </th>
                        <th>{this.state.scheme.releaseAmount[index]} </th>
                        <th>{this.state.scheme.balance[index]} </th>
                        <th>{this.state.scheme.status[index]} </th>
                        <th>{this.state.scheme.edit[index]}</th>
                    </tr>
                );
            });
       
            }
        }

        if(this.props.entryID!==null)
        return(
            <div className="edit"> 
                <Card body className="container-fluid">
                    <CardTitle  className="row">
                        <h2 className="col-8">Head : {this.state.head} </h2>
                        <div className="col-2">
                            <Button onClick={() => {this.editHandler(true)}} block color="secondary" > Update</Button>    
                        </div>
                        <div className="col-2">
                            <Button onClick={() => {this.props.expander(null)}} color="danger" block >Cancel</Button>
                        </div>
                    </CardTitle>
                    <FormGroup row className="bordered">
                        <Label sm={2}>Name Of Work </Label>
                        <Col sm={10}>
                            <h5>{this.state.nameOfWork}</h5>
                        </Col>

                        <Label sm={2}>Gram Panchayat</Label>
                        <Col sm={10}>
                            <h5>{this.state.gramPanchayat}</h5>
                        </Col>

               
                        <Label sm={2}>Sanction No</Label>
                        <Col sm={10}>
                            <h5>{this.state.sancNo}</h5>
                        </Col>
               
                        <Label sm={2}>Sanction Date</Label>
                        <Col sm={10}>
                            <h5>{this.state.sancDate}</h5>
                        </Col>
               
               
                        <Label sm={2}>Financial Year</Label>
                        <Col sm={10}>
                            <h5>{this.state.financialYear}</h5>
                        </Col>
               

                    </FormGroup>

               
                    <div>

                    </div>
                    <CardText> Description : {this.state.description}</CardText>
                    {this.props.type==='Completed'?<Certificate data={this.state.scheme}/>:null}
                    {/* <hr></hr> */}
                    {this.props.type==='In Progress'?<FirstRelease data={this.state.scheme}/>:null}
                    {/* <hr></hr> */}
                    {this.props.type==='Completed'?<FinalRelease data={this.state.scheme}/>:null}
                    {/* <Hindi data={this.state.scheme}/> */}
                         
                <div className="internal-table">
                <Table striped hover responsive="sm">
                        <thead>
                            <tr>
                            <th>Work ID</th>
                            <th>Sanction Amount</th>
                            <th>MB No</th>
                            <th>Page</th>
                            <th>Work Register Page</th>
                            <th>Men Days</th>
                            <th>Date of release</th>
                            <th>Release Amount</th>
                            <th>Balance</th>
                            <th>Status</th>
                            <th>Edit By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableEntries}
                        </tbody>
                    </Table>
                </div>

                    <div className="row">

                        <div  className="col-3">
                            {(this.props.deleteAccess === true && this.state.editing)?<DeleteWork expander={this.props.expander} entryID={this.props.entryID}/>:null}
                        </div>
                        <div className="col-6">
                        </div>
                        
                        <div className="col-2">
                            {this.state.editing?<Button disabled={this.state.result === 'success'} onClick={()=>{this.updateDataHander()}} color={this.state.result === 'not-sent' ? 'primary' : (this.state.result === 'success' ? 'success' : 'danger')} block >Save </Button>:<div></div>}
                        </div>
                        <div className="col-1">
                            {this.state.loading ? <Spinner /> : <div></div>}
                        </div>
                    </div>
                </Card>
            </div>
            );
        else{
            return(<div></div>);
        }
    }
}

export default Edit;