import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import {
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Col,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { capitalizeFirstLetter } from "../../Services/helper";

class SanctionOrder extends Component {
  state = {
    isOpen: false,
    gramPanchayat: "",
    sanctionedFrom: "",
    expM: "",
    expW: "",
    assessedAmount: "",
    contigency: "",
  };
  changeHandler = (event, workID) => {
    this.setState({ [workID]: event.target.checked });
  };

  modalEntryHandler = (event, identifier) => {
    this.setState({ [identifier]: event.target.value });
  };

  produceCertificate = () => {
    let content = this.props.content;
    let selectedContent = content.filter((work) => {
      if (
        this.state[work.workID] != undefined &&
        this.state[work.workID] === true
      ) {
        return true;
      } else {
        return false;
      }
    });

    let ans = selectedContent.map((el, index) => [
      index + 1,
      el.nameOfWork,
      el.workID,
      this.state.sanctionedFrom,
      el.sancNo,
      el.sancDate,
      el.sancAmount,
      el.head,
    ]);
    if (selectedContent.length > 0) {
      const { vfs } = vfsFonts.pdfMake;
      pdfMake.vfs = vfs;

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;

      var dd = {
        content: [
          {
            stack: [
              {
                text: "No. ...........................",
                alignment: "left",
              },
              {
                text: "Office of the Block Dev. Officer  ",
                alignment: "left",
              },
              {
                text: `Development Block ${capitalizeFirstLetter(
                  window.block
                )}`,
                alignment: "left",
              },
            ],
            style: "superMargin",
          },
          {
            text: "To",
          },
          {
            stack: [
              {
                text: "The Pradhan",
                alignment: "left",
              },
              {
                text: `Gram Panchayat ${this.state.gramPanchayat}`,
                alignment: "left",
              },
              {
                text: `Dated ${today} ${capitalizeFirstLetter(window.block)}`,
                alignment: "left",
              },
            ],
            style: "superMargin",
          },
          "\nSubject:            Sanction Order ",

          {
            text: "According to the acceptance order in this office , the following schemes has been sanctioned in your panchayat. ",
            margin: [80, 0, 40, 0],
          },
          "\n",
          {
            style: "tableExample",
            table: {
              widths: [20, "*", 30, 60, 50, 65, 45, 50],
              body: [
                [
                  "S.No",
                  `Name of Scheme`,
                  "Work ID",
                  `Sanctioned From`,
                  `Sanction No`,
                  `Sanction Date`,
                  `Sanction Amount`,
                  `Head`,
                ],
                ...ans,
              ],
            },
          },
          {
            stack: [
              "\nExecute the above sanctioned work according to the following instructions and complete the work within the period of six month.",
              "\n1.  The work will be executed according to the Plan , Estimate and the prescribed criteria approved by the competent authority. ",
              "2.  The work will be executed  by the Panchayat through construction committee constituted as follows :-",
              "a. Pradhan .........................Chairman",
              "b. Pradhan /Up Pradhan / Ward Member ...............................Member No. 1",
              "c. Banificiary such as (P.T.A. / Mahila Mandal / Yuvak Mandal / Users Group ..........Member No. 2)",
              "d. Secratery of executing Committee ..............................Panchayat Secretery",
              "3.  The construction work will be inspected by the government authorized officer and technical officer.  ",
              "4.  In no case will the construction work  be done by the contractor and the construction committee will adopt the principles of transparency and get it done only with the full cooperation of villagers.The detail of all the expenses and wages placed on the persons will be kept regularly before the Panchayat and Executive committee While withdrawing  money from the bank a copy of previous proposal in this regard  will be attached.",
              "5.  Keeping in mind the work efficiency, local laborers will be given priority.",
              "6.  If the work is to be test checked then 10% amount can be held till  test check.",
              "7.  The amount sanctioned for the above work will be spent only on  the work, for which this amount has been approved.",
              "8.  The accounts related to the constructed work will be kept as  per the form prescribed by the government from time to time and the instructions issued in this regard.",
              "9.  The work related accounts will be inspected by the government authorized officials.",
              "10. The same Muster Roll will be used on the work as issued by the Government and on each Muster Roll, a category wise report on the working day will be shown. The muster roll will always be available on the spot.",
              "11. The accounts related to the work will have to be submitted on demand by the Block Development officer or any officer authorised by the government , in the event of non submission of the article, the expenditure  will not be accepted by the government.",
              "12. If the construction work is not found to be as per criteria, then this type of work will be done again  as per criteria and the expenditure incurred on this virtuous construction will be borne by the panchayat.",
              "13. If the actual expenditure on the construction work exceeds the sanctioned amount, then this additional expenditure will have to be borne by the panchayat.",
              "14. After the completion of the construction work, the responsibility of maintenance of this scheme to the satisfaction of the government will be the responsibility of the panchayat / concerned organisation.",
              "15. If the Pardhan or other member of the Panchayat does any work without the confidence of the construction committee at his / her level that the government or the construction work or the public is harmed, then the Pardhan  / Member (not the construction committee) will be personally responsible for it.",
              "16. For construction, the construction committee shall not encroach on forest land, private land or government land and shall not undertake construction work on unauthorized land under any circumstances, if any such issue arises, it will be the fully responsibility of the panchayat / construction committee, Construction work will be started only after transfer of the land to the Panchayat,Government duly concerned and give the documents related to the land to this office ,keep a copy in the Panchayat record and panchayat will have fully responsible for demarcation/identification of land.",
              "17. On  the  completion of work the release of final installment by the Block Development Office to the Panchayat / Construction Committee, the Panchayat / Construction Committee will have fully responsible for any payment related to that construction work.",
              "Therefore , you are requested to complete the above mentioned formalities and submit a proposal for the first installment of any working day and start the construction of the said scheme soon.",
            ],
            alignment: "justify",
          },
          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: "Block Development Officer",
            margin: [240, 0, 40, 0],
          },

          {
            text: `Block Dev. Officer ${capitalizeFirstLetter(
              window.block
            )} District Kullu (HP) `,
            margin: [240, 0, 40, 0],
          },
          "\n",
          "\n",

          "Endorsement No. ..........................Date ..............................",

          "1.   Copy forwarded to the Junior Engineer for information and further necessary action.  ",
          "2.   Copy forwarded to the Panchayat Secretary for information and further necessary action\n",
          "\n",
          "\n",
          "\n",
          "\n",
          {
            text: "\nBlock Development Officer",
            margin: [240, 0, 40, 0],
          },

          {
            text: `Block Dev. Officer ${capitalizeFirstLetter(
              window.block
            )} District Kullu (HP) `,
            margin: [240, 0, 40, 0],
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            alignment: "justify",
          },
          superMargin: {
            margin: [80, 0, 40, 0],
            fontSize: 12,
          },
        },
      };

      var ddd = {
        content: [
          {
            style: "tableExample",
            table: {
              widths: [10, "*", 50, 60, 50, 50, 50, 50],
              body: [
                [
                  "S.No",
                  `Name of Scheme`,
                  "WorkID",
                  `Who gave approval`,
                  `Sanction No`,
                  `Sanction Date`,
                  `Sanction Amount`,
                  `Head`,
                ],
                ...ans,
              ],
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
          },
          subheader: {
            fontSize: 15,
            bold: true,
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
        },
      };

      var pdf = pdfMake.createPdf(dd);
      pdf.download();
    }
  };

  toggle = () => {
    const prev = this.state.isOpen;
    this.setState({ isOpen: !prev });
  };
  render() {
    return (
      <div>
        <Button color="info" onClick={this.toggle} block>
          GENERATE SANCTION ORDERS
        </Button>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          style={{ "max-width": "90%" }}
        >
          <ModalHeader toggle={this.toggle}>
            <div>
              <h2>
                <Badge color="warning">Fill In These Entries</Badge>
              </h2>
            </div>
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup row>
                <Label for="exampleEmail" sm={5}>
                  Gram Panchayat
                </Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    onChange={(event) => {
                      this.modalEntryHandler(event, "gramPanchayat");
                    }}
                    value={this.state.gramPanchayat}
                    id="exampleEmail"
                    placeholder="gram panchayat"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleEmail" sm={5}>
                  Assessed Amount
                </Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    onChange={(event) => {
                      this.modalEntryHandler(event, "sanctionedFrom");
                    }}
                    value={this.state.sanctionedFrom}
                    id="exampleEmail"
                    placeholder=" eg DC"
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup row>
                <Label for="exampleEmail" sm={5}>Expenditure on materials</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'expM')}} value={this.state.expM} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Expenditure on wages</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'expW')}} value={this.state.expW} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="exampleEmail" sm={5}>Less Contigency</Label>
                <Col sm={7}>
                <Input type="number" onChange={(event)=>{this.modalEntryHandler(event,'contigency')}} value={this.state.contigency} id="exampleEmail" placeholder="with a placeholder" />
                </Col>
            </FormGroup> */}
            </Form>

            <Table hover responsive="sm">
              <thead>
                <tr>
                  <th>CHECK</th>
                  <th>#</th>
                  <th>Work ID</th>
                  <th>Head</th>
                  <th>Name of work</th>
                  <th>Gram Panchayat</th>
                  <th>Sanction No</th>
                  <th>Sanction Date</th>
                  <th>Sanction Amount</th>
                  <th>Financial Year</th>
                </tr>
              </thead>

              <tbody>
                {this.props.content.map((work, index) => (
                  <tr key={work.id}>
                    <td>
                      <Input
                        type="checkbox"
                        onChange={(event) =>
                          this.changeHandler(event, work.workID)
                        }
                        checked={
                          this.state[work.workID] != undefined &&
                          this.state[work.workID]
                        }
                      />
                    </td>
                    <th>{index + 1}</th>
                    <td>{work.workID}</td>
                    <td>{work.head}</td>
                    <td>{work.nameOfWork}</td>
                    <td>{work.gramPanchayat}</td>
                    <td>{work.sancNo}</td>
                    <td>{work.sancDate}</td>
                    <td>{work.sancAmount}</td>
                    <td>{work.financialYear}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.produceCertificate}>
              Produce Certificate
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SanctionOrder;
