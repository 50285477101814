import axios from './axios';

export const getUserDetails = async (uid, token) => {
    try {
        const results = await axios.get(`/users/${uid}.json?auth=${token}`);
        return results?.data;
    } catch ( err ) {
        return null;
    }
}

export const updateUser = async (token, uid, userDetails) => {
    const { schemes, panchayats, users } = userDetails;
    try {
        await axios.patch(`/users/${uid}/schemes.json?auth=${token}`, { ...schemes });
    } catch ( err ) {
    }
    try {
        await axios.patch(`/users/${uid}/panchayats.json?auth=${token}`, { ...panchayats});
    } catch ( err ) {
    }
    try {
        await axios.patch(`/users/${uid}/users.json?auth=${token}`, { ...users});
    } catch ( err ) {
    }
}

export const getPanchayats = async (token, block) => {
    try {
        const results = await axios.get(`/blocks/${block}/panchayats.json?auth=${token}`);
        return results?.data;
    } catch (err) {

    }
}

export const createNewPanchayat = async(token, block, panchayat) => {
    try {
        const results = await axios.post(`/blocks/${block}/panchayats.json?auth=${token}`, { name: panchayat});
        return results?.data;
    } catch (err) {

    }
}

export const deletePanchayat = async(token, block, panchayatId) => {
    try {
        const results = await axios.delete(`/blocks/${block}/panchayats/${panchayatId}.json?auth=${token}`);
        return results?.data;
    } catch (err) {

    }
} 

export const createUser = async (uid, token, email) => {
    try {
        await axios.patch(`/unregistered-users/${uid}.json?auth=${token}`, { email });
    } catch (err) {
        return false;
    }
}

export const getUnregisteredUsers = async (token) => {
    try {
        const results = await axios.get(`/unregistered-users.json?auth=${token}`);
        return results?.data;
    } catch (err) {
        return false;
    }
}

export const getRegisteredUsers = async (token) => {
    try {
        const results = await axios.get(`/users.json?auth=${token}`);
        return results?.data;
    } catch (err) {
        return false;
    }
}


export const registerUser = async(uid, token, email, block) => {
    try {
        await axios.delete(`/unregistered-users/${uid}.json?auth=${token}`);
        await axios.patch(`/users/${uid}.json?auth=${token}`, { email, block });
    } catch (err) {
        createUser(uid, token, email);
        return false;
    }
}

export const deregisterUser = async(uid, token, email) => {
    try {
        await axios.delete(`/users/${uid}.json?auth=${token}`);
        await createUser(uid, token, email);
        
    } catch (err) {

    }
}