import React, {Component} from 'react';
import {Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from '../../Services/axios';


class DeleteWork extends Component{
    state={
        isOpen:false,
        result: 'not-sent' 
    }
    toggle=()=>{
        const prev = this.state.isOpen;
        this.setState({isOpen:!prev});
    }
    deleteHandler = () =>{
        axios.delete(`blocks/${window.block}/schemes/${this.props.entryID}.json?auth=${window.token}`).then(response => {
            this.setState({ result: 'success', loading: false ,isOpen:false});
            this.props.expander(null);
            }).catch(error => {
            this.setState({ result: 'failed', loading: false });
            });
      }
    
    render(){
        return(
            <div>
            <Button color="danger" disabled={this.state.result==='success'?true:false} onClick={this.toggle}>Delete This work</Button>
            <Modal isOpen={this.state.isOpen} toggle={this.toggle} >
              <ModalHeader toggle={this.toggle}><div><h2><Badge color="warning">Warning !</Badge></h2></div></ModalHeader>
             
              <ModalBody>
                 <p>Once Deleted ! this data can never be recovered.</p>
                 <p><strong>Sure Want to delete ???</strong></p>
                 
             </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.deleteHandler}>Delete Anyway</Button>{' '}
                <Button color="secondary" onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </div> 
        );
    }
}

export default DeleteWork;
// const ModalExample = (props) => {
//   const {
//     buttonLabel,
//     className
//   } = props;

//   const [modal, setModal] = useState(false);

//   const toggle = () => setModal(!modal);

//   const deleteHandler = () =>{
//     axios.delete(`/schemes/${props.entryID}.json?auth=${window.token}`).then(response => {
//         console.log(response);
//         this.setState({ result: 'success', loading: false });
//         }).catch(error => {
//         console.log(error);
//         this.setState({ result: 'failed', loading: false });
//         });
//   }


//   return (
//     <div>
//       <Button color="danger" onClick={toggle}>Delete This work</Button>
//       <Modal isOpen={modal} toggle={toggle} className={className}>
//         <ModalHeader toggle={toggle}> <h2><Badge color="warning">Warning !</Badge></h2></ModalHeader>
       
//         <ModalBody>
//            <p>Once Deleted ! this data can never be recovered.</p>
//            <p><strong>Sure Want to delete ???</strong></p>
           
//        </ModalBody>
//         <ModalFooter>
//           <Button color="danger" onClick={deleteHandler}>Delete Anyway</Button>{' '}
//           <Button color="secondary" onClick={toggle}>Cancel</Button>
//         </ModalFooter>
//       </Modal>
//     </div>
//   );
// }

// export default ModalExample;

